<script setup lang="ts">
import { ref, computed, watch } from 'vue';

import type { SelectOption } from '@/types/select-option';

interface Props {
  modelKey?: 'id' | 'name';
  firstLabel: string;
  secondLabel: string;
  firstName: string;
  secondName: string;
  firstLevelOptions: SelectOption[];
  secondLevelOptions: SelectOption[];
}

const props = withDefaults(defineProps<Props>(), {
  modelKey: 'id',
});

const firstLevelValue = ref(props.firstLevelOptions[0].name);
const filteredSecondLevelOptions = computed(
  () => props.secondLevelOptions.filter((option) => option.parentKey === firstLevelValue.value),
);
const secondLevelValue = ref(filteredSecondLevelOptions.value[0][props.modelKey]);

watch(firstLevelValue, () => {
  secondLevelValue.value = filteredSecondLevelOptions.value[0][props.modelKey];
},
);
</script>
<template>
  <base-select
    v-model="firstLevelValue"
    :model-key="modelKey"
    :label="firstLabel"
    :name="firstName"
    :options="firstLevelOptions"
  />
  <div>
    <base-select
      v-model="secondLevelValue"
      :model-key="modelKey"
      :label="secondLabel"
      :name="secondName"
      :options="filteredSecondLevelOptions"
    />
    <slot />
  </div>
</template>
