<script setup lang="ts">
import { computed, inject } from 'vue';

import useSiteVariables from '@/composables/useSiteVariables';
import type { LoyaltyInfoModalNames } from '@/types/loyalty-info-modal-names';
import type { Service } from '@/types/service';

import LoyaltyRewardableContentCard from './loyalty-rewardable-content-card.vue';

interface Props {
  services: Service[];
}

const props = defineProps<Props>();
const { hasLoyalty } = useSiteVariables();
const visible = computed(() => hasLoyalty && props.services.length > 0);

const openLoyaltyModal = inject('openLoyaltyModal') as (key: LoyaltyInfoModalNames) => void;

function handleInfoClicked() {
  openLoyaltyModal('loyalty-reward-info-modal');
}

</script>

<template>
  <div
    v-if="visible"
    id="loyaltyRewardableContent"
    class="w-full bg-gray-50 py-14"
  >
    <div class="mx-auto px-6 sm:px-16 md:max-w-screen-2xl">
      <base-swipeable-cards
        :title="$t('loyalty.rewardableContent.title')"
        :description="$t('loyalty.rewardableContent.description')"
        :cards-count="props.services.length"
      >
        <template #info-button>
          <base-button
            class="mb-4 mt-3 px-6 py-1.5"
            theme="primary-button-outline"
            @click="handleInfoClicked"
          >
            {{ $t('loyalty.rewardableContent.moreInformation') }}
          </base-button>
        </template>
        <template #cards>
          <loyalty-rewardable-content-card
            v-for="service in services"
            :key="service.id"
            :service="service"
          />
        </template>
      </base-swipeable-cards>
    </div>
  </div>
</template>
