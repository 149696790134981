import formatNumericString from '../format-numeric-string';

const BRASIL_NATIONAL_ID_LENGTH = 11;
const CHECK_DIGIT_INITIAL_MULTIPLIER = 2;
const CHECK_DIGIT_SUM_DIVISOR = 11;
const CHECK_DIGIT_REMAINDER_LIMIT = 2;

function isCheckDigitValid(nationalIdPart:string) {
  const checkDigit = parseInt(nationalIdPart.slice(-1), 10);
  const nationalIdPartWithoutCheckDigit = nationalIdPart.slice(0, -1);
  const sum = nationalIdPartWithoutCheckDigit.split('').reverse().reduce(
    (acc, digit, index) => acc + (parseInt(digit, 10) * (index + CHECK_DIGIT_INITIAL_MULTIPLIER)),
    0,
  );

  const remainder = sum % CHECK_DIGIT_SUM_DIVISOR;
  const expectedCheckDigit = remainder < CHECK_DIGIT_REMAINDER_LIMIT ? 0 : CHECK_DIGIT_SUM_DIVISOR - remainder;

  return checkDigit === expectedCheckDigit;
}

function clean(nationalId: string) {
  return nationalId.replace(/\D/g, '');
}

function format(nationalId: string) {
  const cleanId = clean(nationalId);
  const checkDigitsLength = 2;
  const mainPart = cleanId.slice(0, -checkDigitsLength);
  const lastTwoDigits = cleanId.slice(-checkDigitsLength);
  const formattedMainPart = formatNumericString(mainPart);
  const formattedMainPartWithDash = formattedMainPart ? `${formattedMainPart}-` : '';

  return `${formattedMainPartWithDash}${lastTwoDigits}`;
}

function validate(nationalId: string) {
  const cleanNationalId = clean(nationalId);
  const hasCorrectLength = cleanNationalId.length === BRASIL_NATIONAL_ID_LENGTH;
  const isFirstCheckDigitValid = isCheckDigitValid(cleanNationalId.slice(0, -1));
  const isSecondCheckDigitValid = isCheckDigitValid(cleanNationalId);

  return hasCorrectLength && isFirstCheckDigitValid && isSecondCheckDigitValid;
}

export default { format, validate };
