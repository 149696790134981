<script setup lang="ts">
import {
  Dialog,
  DialogOverlay,
} from '@headlessui/vue';

interface Props {
  open: boolean;
  title?: string;
  positionOption: 'top' | 'mixed';
  customWidth?: string;
  customHeight?: string;
}

const props = withDefaults(defineProps<Props>(), {
  open: false,
  title: undefined,
  customWidth: undefined,
  customHeight: undefined,
});

const boxWidth = props.customWidth ? props.customWidth : 'md:w-auto';
const boxHeight = props.customHeight ? props.customHeight : 'md:h-auto';

defineEmits<{(e: 'close'): void}>();

</script>
<template>
  <Dialog
    :open="open"
    class="fixed inset-0 z-50 flex flex-col items-center"
    :class="{'md:justify-center': positionOption === 'mixed'}"
    @close="() => $emit('close')"
  >
    <DialogOverlay
      class="fixed inset-0 bg-black opacity-60"
    />
    <div
      class="w-full md:w-auto"
      :class="[{'absolute bottom-0 md:static': positionOption === 'mixed'}, boxHeight]"
    >
      <div
        class="relative flex flex-col overflow-y-auto rounded-md bg-white md:mx-auto xl:max-w-screen-xl 2xl:max-w-screen-2xl"
        :class="[{'mt-20': positionOption === 'top'}, boxWidth, boxHeight]"
      >
        <div class="flex p-8">
          <div
            v-if="title"
            class="flex items-center text-gray-600"
          >
            <h1 class="text-2xl md:text-3xl">
              {{ title }}
            </h1>
            <button
              class="ml-auto"
              @click="() => $emit('close')"
            >
              <base-svg
                name="close"
                class="size-4 fill-current md:size-6"
              />
            </button>
          </div>
          <slot :close="() => $emit('close')" />
        </div>
      </div>
    </div>
  </Dialog>
</template>
