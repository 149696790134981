<script setup lang="ts">
import { useField } from 'vee-validate';

import useAttrsWithoutClass from '@/composables/useAttrsWithoutClass';

const labelSizeClass = {
  xs: 'text-xs',
  sm: 'text-sm',
};

const labelWeightClass = {
  bold: 'font-bold',
  semibold: 'font-semibold',
  normal: 'font-normal',
};

const inputSizeClass = {
  xs: 'h-8 placeholder:text-xs',
  sm: 'h-10 placeholder:text-sm',
};

interface Props {
  modelValue?: string;
  label: string;
  name: string;
  type?: string;
  is?: 'input' | 'textarea';
  size?: keyof typeof labelSizeClass;
  weight?: keyof typeof labelWeightClass;
  description?: string;
  initialValue?: string;
  optional?: boolean;
  disabled?: boolean;
  disabledDescription?: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  type: 'text',
  is: 'input',
  size: 'sm',
  weight: 'normal',
  description: undefined,
  initialValue: undefined,
  optional: false,
  disabled: false,
  disabledDescription: undefined,
});

const attrsWithoutClass = useAttrsWithoutClass();

const {
  value: inputValue,
  errorMessage,
  meta,
  handleBlur,
  handleChange,
} = useField(props.name, undefined, {
  initialValue: props.initialValue || props.modelValue,
  syncVModel: true,
});

</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<template>
  <div
    class="flex flex-col space-y-1"
    :class="$attrs.class"
  >
    <label
      :for="name"
      class="text-gray-700"
      :class="[labelSizeClass[size], labelWeightClass[weight]]"
    >
      {{ label }}
      <span
        v-if="optional"
        class="text-xs opacity-40"
      >
        ({{ $t('common.optional') }})
      </span>
      <span
        v-else
        class="text-xs text-red-500"
      >
        *
      </span>
    </label>
    <div
      class="flex w-full rounded border px-2 text-gray-700"
      :class="[{
        'border-error-600': !!errorMessage && meta.touched,
        'border-gray-300': !errorMessage && !meta.touched,
        'bg-gray-100': disabled,
      }, inputSizeClass[size]]"
    >
      <component
        :is="is"
        :id="name"
        :name="name"
        :value="inputValue"
        :type="type"
        class="grow focus:outline-none"
        :disabled="disabled"
        v-bind="attrsWithoutClass"
        @input="handleChange"
        @blur="handleBlur"
      />
      <base-tooltip
        v-if="disabled && disabledDescription"
        :text="disabledDescription"
      >
        <div class="flex h-full items-center">
          <base-svg
            name="info-circle"
            class="size-4 stroke-primary"
          />
        </div>
      </base-tooltip>
    </div>
    <p
      v-show="description"
      class="text-left text-xs text-gray-400"
    >
      {{ description }}
    </p>
    <p
      v-show="!!errorMessage && meta.touched"
      class="text-left text-xs text-error-600"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
