import fetchApi from './fetch-client';

import api from './index';

const BASE_PATH = '/api/internal/events';

export default {
  send(event: string, serviceId: number, extraParams: Record<string, unknown> = {}) {
    const path = `${BASE_PATH}`;

    return api({
      method: 'post',
      url: path,
      data: {
        event,
        serviceId,
        ...extraParams,
      },
    });
  },
  sendKeepAlive(event: string, serviceId: number, extraParams: Record<string, unknown> = {}) {
    const path = `${BASE_PATH}`;

    return fetchApi({
      method: 'POST',
      path,
      keepalive: true,
      params: {
        event,
        serviceId,
        ...extraParams,
      },
    });
  },
  selectFamily(familyId: number) {
    const path = `${BASE_PATH}`;

    return api({
      method: 'post',
      url: path,
      data: {
        event: 'service_family',
        familyId,
      },
    });
  },
  selectAllFamilies() {
    const path = `${BASE_PATH}`;

    return api({
      method: 'post',
      url: path,
      data: { event: 'select_all_families' },
    });
  },
};
