<script setup lang="ts">
import '@vuepic/vue-datepicker/dist/main.css';

import VueDatePicker from '@vuepic/vue-datepicker';
import moment from 'moment';
import { useField } from 'vee-validate';
import { toRef } from 'vue';
import { useI18n } from 'vue-i18n';

const SUNDAY_INDEX = 0;
const SATURDAY_INDEX = 6;

const { t } = useI18n();

const labelSizeClass = {
  xs: 'text-xs',
  sm: 'text-sm',
};

const labelWeightClass = {
  bold: 'font-bold',
  semibold: 'font-semibold',
  normal: 'font-normal',
};

const inputSizeClass = {
  xs: 'h-8 placeholder:text-xs',
  sm: 'h-10 placeholder:text-sm',
};

interface Props {
  modelValue?: Date;
  label: string;
  name: string;
  size?: keyof typeof labelSizeClass;
  weight?: keyof typeof labelWeightClass;
  description?: string;
  optional?: boolean;
  disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  size: 'sm',
  weight: 'normal',
  description: undefined,
  optional: false,
  disabled: false,
});

const name = toRef(props, 'name');

const {
  value: inputValue,
  errorMessage,
  meta,
  handleBlur,
  handleChange,
} = useField<Date>(name, undefined);

const tomorrow = moment().add(1, 'days').toString();

const weekDayNames = [
  t('common.shortDate.monday'),
  t('common.shortDate.tuesday'),
  t('common.shortDate.wednesday'),
  t('common.shortDate.thursday'),
  t('common.shortDate.friday'),
  t('common.shortDate.saturday'),
  t('common.shortDate.sunday'),
];
</script>
<template>
  <div
    class="flex flex-col space-y-1"
    :class="$attrs.class"
  >
    <label
      :for="name"
      class="text-gray-700"
      :class="[labelSizeClass[props.size], labelWeightClass[props.weight]]"
    >
      {{ label }}
      <span
        v-if="props.optional"
        class="text-xs opacity-40"
      >
        ({{ $t('common.optional') }})
      </span>
      <span
        v-else
        class="text-xs text-red-500"
      >
        *
      </span>
    </label>
    <vue-date-picker
      :name="name"
      :uid="name"
      :select-text="$t('common.select')"
      :cancel-text="$t('common.cancel')"
      :model-value="inputValue"
      model-type="yyyy-MM-dd"
      :disabled="disabled"
      :disabled-week-days="[SUNDAY_INDEX, SATURDAY_INDEX]"
      :day-names="weekDayNames"
      :ui="{ input: `${inputSizeClass[props.size]} ${!!errorMessage && '!border-error-600'}` }"
      :enable-time-picker="false"
      :min-date="tomorrow"
      format="dd/MM/yyyy"
      @update:model-value="handleChange"
      @blur="handleBlur"
    />
    <p
      v-show="description"
      class="text-left text-xs text-gray-400"
    >
      {{ description }}
    </p>
    <p
      v-show="!!errorMessage && meta.touched"
      class="text-left text-xs text-red-500"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
<style scoped>
.dp__theme_light {
  --dp-border-color-hover: theme('colors.gray.300');
  --dp-border-color-focus: theme('colors.gray.300');
}
</style>
<style>
.dp__theme_light {
  --dp-primary-color: theme('colors.primary.DEFAULT');
}
</style>
