<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import type { NestedInput } from '@/types/nested-input';

interface Props {
  input: NestedInput;
  translationPath: string;
}
const props = defineProps<Props>();

const { t } = useI18n({});

defineEmits<{(e: 'update:modelValue', value: string | number): void}>();

const isEditable = ref(props.input.inputType === 'nestedEditableSelect');
const isOnEdit = ref(false);

function edit() {
  isOnEdit.value = true;
}

const firstLabel = props.input.firstCustomName || t(`${props.translationPath}.${props.input.firstName}`);
const firstName = `${props.input.firstName}-${props.input.kind}`;

const secondLabel = props.input.secondCustomName || t(`${props.translationPath}.${props.input.secondName}`);
const secondName = `${props.input.secondName}-${props.input.kind}`;

</script>

<template>
  <base-nested-select
    v-if="!isOnEdit"
    :model-key="input.modelKey"
    :first-name="firstName"
    :second-name="secondName"
    :first-label="firstLabel"
    :second-label="secondLabel"
    :first-level-options="input.firstLevelOptions"
    :second-level-options="input.secondLevelOptions"
  >
    <base-link
      v-if="isEditable"
      type="button"
      theme="primary"
      :label="$t(`${translationPath}.editNested`, { first: firstLabel, second: secondLabel})"
      @click="edit"
    />
  </base-nested-select>
  <template v-else>
    <base-input
      :initial-value="firstLabel"
      :name="firstName"
      :label="firstLabel"
      :placeholder="firstLabel"
      type="text"
    />
    <base-input
      :initial-value="secondLabel"
      :name="secondName"
      :label="secondLabel"
      :placeholder="secondLabel"
      type="text"
    />
  </template>
</template>
