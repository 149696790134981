<script setup lang="ts">
import { computed, type ComputedRef } from 'vue';

import useFamilyServicesFilters from '@/composables/useFamilyServicesFilters';
import { FifthMainContent } from '@/types/fifth-main-content';

import FifthMainContentAllServices from './fifth-main-content-all-services.vue';
import FifthMainContentServiceCard from './fifth-main-content-service-card.vue';

interface Props {
  fifthMainContent: FifthMainContent;
}

const props = defineProps<Props>();

const families = computed(() => props.fifthMainContent.families);
const {
  couponFamily,
  familiesWithAllServices,
  hasFamiliesOrCoupons,
  selectedFamilyIndex,
  selectFamily,
  services,
} = useFamilyServicesFilters(families);

const showFilters = computed(() => !props.fifthMainContent.hideFilters);

const showAllServices = computed(() => selectedFamilyIndex.value === 0);

const cardsCount = computed(() => services.value.length);

const iconColor : ComputedRef<'primary' | 'secondary' > = computed(
  () => props.fifthMainContent.iconColor || 'primary',
);
const selectedFamilyTextClass = computed(() => {
  const colorClassByIconColor = {
    primary: 'text-primary',
    secondary: 'text-secondary',
  };

  return colorClassByIconColor[iconColor.value];
});

const selectedFamilyBorderClass = computed(() => {
  const colorClassByIconColor = {
    primary: 'border-b-2 border-primary',
    secondary: 'border-b-2 border-secondary',
  };

  return colorClassByIconColor[iconColor.value];
});

function isFamilySelected(index: number) {
  return selectedFamilyIndex.value === index;
}
</script>
<template>
  <template v-if="hasFamiliesOrCoupons">
    <div
      id="main-content"
      class="mx-auto flex w-full flex-col gap-y-7 pb-7 pt-12 sm:pt-24 md:max-w-screen-2xl"
    >
      <h2 class="px-6 text-2xl font-medium sm:px-16 sm:text-center sm:text-5xl">
        {{ props.fifthMainContent.title ?? $t('sections.mainContents.shared.title') }}
      </h2>
      <div
        v-if="showFilters"
        class="flex w-full max-w-min gap-x-6 self-center overflow-x-auto border-b border-gray-300 px-6 sm:mx-16 sm:gap-x-16 sm:px-0 [&::-webkit-scrollbar]:hidden"
      >
        <button
          v-for="(family, index) in familiesWithAllServices"
          :key="family.id"
          data-testid="filter-button"
          class="relative overflow-visible pb-1"
          :class="{ [selectedFamilyBorderClass]: isFamilySelected(index)}"
          @click="selectFamily(index)"
        >
          <span
            class="whitespace-nowrap text-sm font-medium sm:text-base"
            :class="{ [selectedFamilyTextClass]: isFamilySelected(index)}"
          >
            {{ family.name }}
          </span>
        </button>
        <a
          v-if="couponFamily"
          data-testid="coupons-button"
          :href="couponFamily.showPath"
          class="focus:border-b-2 focus:border-primary focus:text-primary"
        >
          <span class="whitespace-nowrap text-sm font-medium sm:text-base">
            {{ $t('coupons.titleShort') }}
          </span>
        </a>
      </div>
    </div>
    <fifth-main-content-all-services
      v-if="showAllServices"
      :families="families"
      :services="services"
      :icon-color="iconColor"
    />
    <div
      v-else
      class="w-full bg-gray-100 pb-14 pt-4 sm:pt-16"
    >
      <base-swipeable-cards
        class="overflow-y-hidden px-6 sm:px-16 md:max-w-screen-2xl"
        :cards-count="cardsCount"
        :swipe-buttons-theme="iconColor"
      >
        <template #cards>
          <fifth-main-content-service-card
            v-for="service in services"
            :key="`service-${service.id}`"
            :service="service"
            :icon-color="iconColor"
          />
        </template>
      </base-swipeable-cards>
    </div>
  </template>
</template>
