<script setup lang="ts">
import { computed, inject, type Ref } from 'vue';

import useSiteVariables from '@/composables/useSiteVariables';
import type { User } from '@/types/user';
import formatNumber from '@/utils/format-number';
import getUserInitials from '@/utils/get-user-initials';

interface Props {
  currentUser: User;
}

const props = defineProps<Props>();
const userInitials = getUserInitials(props.currentUser, 1);
const userFirstName = computed(() => props.currentUser.firstName);
const userLastName = computed(() => props.currentUser.lastName);
const points = inject<Ref<number | undefined>>('points');
const units = inject<string | undefined>('units');
const { hasLoyalty } = useSiteVariables();

</script>

<template>
  <div class="flex flex-row items-center">
    <div class="flex size-12 items-center justify-center rounded-full bg-slate-50 text-lg font-bold">
      {{ userInitials }}
    </div>
    <div class="flex flex-col">
      <span class="ml-2 text-sm font-bold">
        {{ `${userFirstName} ${userLastName}` }}
      </span>
      <span
        v-if="hasLoyalty"
        class="ml-2 text-sm font-bold text-secondary"
      >
        {{ `${formatNumber(points)} ${units}` }}
      </span>
    </div>
  </div>
</template>
