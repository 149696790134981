<script setup lang="ts">
import { computed } from 'vue';

import type { CuponstarCategory } from '@/types/cuponstar-category';

import CouponCard from './coupon-card.vue';

interface Emits {
  (e: 'call-to-action-clicked', index: number): void;
}

defineEmits<Emits>();

interface Props {
  cuponstarCategory: CuponstarCategory;
  mode: 'redeemable' | 'included';
}

const props = defineProps<Props>();

const coupons = computed(() => (
  props.mode === 'redeemable' ?
    props.cuponstarCategory.redeemableCoupons :
    props.cuponstarCategory.includedCoupons
));
</script>
<template>
  <div class="py-6 md:px-16 md:py-8">
    <h3 class="pl-6 text-lg font-bold md:pl-0">
      {{ cuponstarCategory.name }}
    </h3>
    <base-swipeable-cards
      class="ml-0"
    >
      <template #cards>
        <coupon-card
          v-for="(coupon, index) in coupons"
          :key="coupon.id"
          class="ml-1"
          :class="index === 0 && 'ml-6 md:ml-1'"
          :coupon="coupon"
          @call-to-action-clicked="() => $emit('call-to-action-clicked', index)"
        />
      </template>
    </base-swipeable-cards>
  </div>
</template>
