<script setup lang="ts">
import useFamiliesWithIncludedCoupons from '@/composables/useFamiliesWithIncludedCoupons';
import type { SecondMainContent } from '@/types/second-main-content';
import familyIconSrc from '@/utils/family-icon-src';

import SecondMainContentCard from './second-main-content-card.vue';

interface Props {
  secondMainContent: SecondMainContent;
}

const props = defineProps<Props>();
const { families, hasFamilies } = useFamiliesWithIncludedCoupons(props.secondMainContent.families);

</script>

<template>
  <div
    v-if="hasFamilies"
    id="main-content"
    class="mx-auto mb-20 mt-7 grid grid-cols-1 gap-6 px-6 sm:px-16 md:mt-10 md:max-w-screen-2xl md:grid-cols-3"
  >
    <second-main-content-card
      v-for="family in families"
      :key="family.id"
      :title="family.name"
      :icon-src="familyIconSrc(family)"
      :icon-color="secondMainContent.iconColor"
      :link="family.showPath"
      :link-text="$t('sections.mainContents.secondMainContent.cardCta')"
    />
  </div>
</template>
