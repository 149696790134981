type gridItemClassesParams = {
  itemIndex: number;
  numberOfItems: number;
};

const gridColumnSpan = {
  generalPattern: `
      [&:nth-child(10n+1)]:col-span-5
      [&:nth-child(10n+7)]:col-span-5
      [&:nth-child(10n+2)]:col-span-4
      [&:nth-child(10n+6)]:col-span-4
      [&:nth-child(5n+3)]:col-span-3
      [&:nth-child(5n+4)]:col-span-3
      [&:nth-child(5n+5)]:col-span-3
    `,
  lastChildrenFirstPattern: `
      [&:nth-last-child(1)]:col-span-5
      [&:nth-last-child(2)]:col-span-4
      [&:nth-last-child(3)]:col-span-4
      [&:nth-last-child(4)]:col-span-5
      [&:nth-last-child(5)]:col-span-5
      [&:nth-last-child(6)]:col-span-4
    `,
  lastChildrenSecondPattern: `
      [&:nth-last-child(1)]:col-span-4
      [&:nth-last-child(2)]:col-span-5
      [&:nth-last-child(3)]:col-span-5
      [&:nth-last-child(4)]:col-span-4
      [&:nth-last-child(5)]:col-span-4
      [&:nth-last-child(6)]:col-span-5
    `,
  sameWidthPattern: 'col-span-3',
};

type belongsToLastNItemsParams = {
  itemIndex: number;
  numberOfItems: number;
  n: number;
};

function belongsToLastNItems(params: belongsToLastNItemsParams) {
  const { itemIndex, numberOfItems, n } = params;

  return itemIndex >= numberOfItems - n;
}

/* eslint-disable no-magic-numbers */
function existsInFirstPattern(n: number) {
  return n % 5 === 0 || (n + 3) % 5 === 0;
}

function existsInSecondPattern(n: number) {
  return (n + 1) % 5 === 0;
}

function getSecondGridItemClasses(itemIndex: number, numberOfItems: number) {
  if (belongsToLastNItems({ itemIndex, numberOfItems, n: 4 })) {
    if ((numberOfItems + 1) % 10 === 5) {
      return gridColumnSpan.lastChildrenFirstPattern;
    }

    return gridColumnSpan.lastChildrenSecondPattern;
  }

  return gridColumnSpan.generalPattern;
}

function getThirdGridItemClasses(itemIndex: number, numberOfItems: number) {
  if (belongsToLastNItems({ itemIndex, numberOfItems, n: 6 })) {
    if ((numberOfItems % 10) % 3 === 0) {
      return gridColumnSpan.lastChildrenSecondPattern;
    }

    return gridColumnSpan.lastChildrenFirstPattern;
  }

  return gridColumnSpan.generalPattern;
}

function getFirstGridItemClasses() {
  return gridColumnSpan.generalPattern;
}

export default function gridItemClasses({ itemIndex, numberOfItems }: gridItemClassesParams) {
  if (numberOfItems === 3) {
    return gridColumnSpan.sameWidthPattern;
  }

  if (existsInFirstPattern(numberOfItems)) {
    return getFirstGridItemClasses();
  }

  if (existsInSecondPattern(numberOfItems)) {
    return getSecondGridItemClasses(itemIndex, numberOfItems);
  }

  return getThirdGridItemClasses(itemIndex, numberOfItems);
}
