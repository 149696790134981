<script setup lang="ts">
import { computed, ref, inject, type ComputedRef } from 'vue';
import { useQuery } from 'vue-query';

import redeemableFamiliesApi from '@/api/redeemable-families';
import useShowRedeemableCoupons from '@/composables/useShowRedeemableCoupons';
import useSiteVariables from '@/composables/useSiteVariables';
import type { Category } from '@/types/category';
import type { LoyaltyInfoModalNames } from '@/types/loyalty-info-modal-names';

import LoyaltyRedeemableContentCard from './loyalty-redeemable-content-card.vue';

interface Props {
  families: Category[];
}

const props = defineProps<Props>();

const openLoyaltyModal = inject('openLoyaltyModal') as (key: LoyaltyInfoModalNames) => void;
function handleInfoClicked() {
  openLoyaltyModal('loyalty-redemption-info-modal');
}

const { hasLoyalty } = useSiteVariables();
const visible = computed(() => hasLoyalty && props.families.length > 0);

const { data: updatedFamiliesOrUndefined } = useQuery(
  'redeemable-families',
  redeemableFamiliesApi.index, {
    initialData: props.families,
    enabled: visible,
  },
);

const updatedFamilies : ComputedRef<Category[]> = computed(() => updatedFamiliesOrUndefined.value as Category[]);
const selectedFamilyIndex = ref(0);
const selectedFamily = computed(() => updatedFamilies.value[selectedFamilyIndex.value]);

const filteredServices = computed(() => {
  if (selectedFamily.value?.allRedeemableServices) {
    return selectedFamily.value.allRedeemableServices;
  }

  return [];
});

const filteredServicesCount = computed(() => filteredServices.value.length);

function familyButtonTheme(familyId: number) {
  if (selectedFamily.value?.id === familyId) {
    return 'primary-button';
  }

  return 'primary-button-outline';
}

function selectFamily(index: number) {
  selectedFamilyIndex.value = index;
}

const { showRedeemableCoupons } = useShowRedeemableCoupons();
</script>

<template>
  <div
    v-if="visible"
    id="loyaltyRedeemableContent"
    class="mx-auto w-full self-center px-6 py-14 sm:px-16 md:max-w-screen-2xl"
  >
    <base-swipeable-cards
      :title="$t('loyalty.redeemableContent.title')"
      :description="$t('loyalty.redeemableContent.description')"
      :cards-count="filteredServicesCount"
    >
      <template #title-icon>
        <base-svg
          name="store"
          class="mr-3 size-7 fill-secondary"
        />
      </template>
      <template #info-button>
        <base-button
          class="mb-4 mt-3 px-6 py-1.5"
          theme="primary-button-outline"
          @click="handleInfoClicked"
        >
          {{ $t('loyalty.redeemableContent.moreInformation') }}
        </base-button>
      </template>
      <template #filter-buttons>
        <div
          id="families"
          class="flex flex-row overflow-x-auto"
        >
          <base-button
            v-for="(family, index) in families"
            :key="family.id"
            type="button"
            :theme="familyButtonTheme(family.id)"
            class="mb-4 mr-3 whitespace-nowrap rounded-full px-5 py-2 text-sm"
            rounded="full"
            @click="() => selectFamily(index)"
          >
            {{ family.name }}
          </base-button>
          <base-button
            v-if="showRedeemableCoupons"
            type="button"
            class="mb-4 whitespace-nowrap rounded-full px-5 py-2 text-sm"
            rounded="full"
            theme="primary-button-outline"
            href="/coupons/redeemable"
          >
            {{ $t('loyalty.redeemableContent.coupons') }}
          </base-button>
        </div>
      </template>
      <template #cards>
        <loyalty-redeemable-content-card
          v-for="service in filteredServices"
          :key="service.id"
          :service="service"
        />
      </template>
    </base-swipeable-cards>
    <div />
  </div>
</template>
