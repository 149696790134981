<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import EducationImage from '@assets/images/coupons/education.png';
import FoodImage from '@assets/images/coupons/food.png';
import HealthImage from '@assets/images/coupons/health.png';
import ShoppingImage from '@assets/images/coupons/shopping.png';

import CouponsViewHeroImage from './coupons-view-hero-image.vue';

const { t } = useI18n();

const couponsViewHeroImagesData = [
  {
    imageName: HealthImage,
    svgName: 'cardiology',
    title: t('coupons.health'),
  },
  {
    imageName: FoodImage,
    svgName: 'skillet',
    title: t('coupons.food'),
  },
  {
    imageName: ShoppingImage,
    svgName: 'shopping-bag',
    title: t('coupons.shopping'),
  },
  {
    imageName: EducationImage,
    svgName: 'school',
    title: t('coupons.education'),
  },
];
</script>
<template>
  <div class="flex h-[28.75rem] w-full flex-col gap-8 md:flex-row md:gap-0">
    <div class="mx-6 flex flex-col items-center justify-center gap-3 md:mx-0 md:basis-5/12">
      <div class="w-full text-4xl font-bold leading-10 md:w-110 md:text-6xl md:leading-18">
        {{ $t('coupons.title') }}
      </div>
      <div class="w-full leading-6 md:w-110">
        {{ $t('coupons.subtitle') }}
      </div>
      <div class="hidden w-110 text-2xs md:block">
        {{ $t('coupons.disclaimer') }}
      </div>
    </div>
    <div class="flex h-[21.25rem] flex-row overflow-x-auto text-center md:h-auto md:basis-7/12 md:overflow-x-clip">
      <coupons-view-hero-image
        v-for="(coupon, index) in couponsViewHeroImagesData"
        :key="index"
        :image="coupon.imageName"
        :svg-name="coupon.svgName"
        :title="coupon.title"
      />
    </div>
  </div>
</template>
