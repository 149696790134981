<script setup lang="ts">
import { ref } from 'vue';

import type { User } from '@/types/user';

import FirstNavbarProfile from './first-navbar-profile.vue';
import NavbarMenuDrawer from './navbar-menu-drawer.vue';

interface Props {
  currentUser: User;
  logoUrl?: string;
  serviceRedemptionsPath: string;
  editUserPath?: string;
}

withDefaults(defineProps<Props>(), {
  logoUrl: undefined,
  editUserPath: undefined,
});

const menuIsOpen = ref(false);

function toggleMenu() {
  menuIsOpen.value = !menuIsOpen.value;
}

function closeMenu() {
  menuIsOpen.value = false;
}
</script>

<template>
  <div>
    <navbar-menu-drawer
      v-if="menuIsOpen"
      :logo-url="logoUrl"
      :edit-user-path="editUserPath"
      :service-redemptions-path="serviceRedemptionsPath"

      @close="closeMenu"
    >
      <template #profile>
        <first-navbar-profile
          :current-user="currentUser"
        />
      </template>
    </navbar-menu-drawer>
    <div
      v-else
      class="flex cursor-pointer items-center"
    >
      <button
        class="mt-4 w-full cursor-text items-center py-5 pl-4 md:mt-0 md:flex md:border-none"
        @click="toggleMenu()"
      >
        <first-navbar-profile
          class="hidden md:flex"
          :current-user="currentUser"
        />

        <base-svg
          name="menu"
          class="ml-12 size-6 fill-current"
        />
      </button>
    </div>
  </div>
</template>
