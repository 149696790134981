<script setup lang="ts">
import { useField } from 'vee-validate';
import { inject, ref } from 'vue';
import VueGoogleAutocomplete from 'vue-google-autocomplete';

import type { Country } from '@/types/country';
import type { GoogleAutocompleteAddressData } from '@/types/google-autocomplete-response';

interface Props {
  modelValue?: GoogleAutocompleteAddressData;
  label: string;
  name: string;
  initialValue?: GoogleAutocompleteAddressData;
  optional: boolean;
}

const googleAutocompleteAddressData = ref<GoogleAutocompleteAddressData>();
const googleAutocomplete = ref<typeof VueGoogleAutocomplete | null>(null);

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  initialValue: undefined,
});

const emit = defineEmits<{(e: 'update:modelValue', value: GoogleAutocompleteAddressData | undefined): void}>();

const country = inject<Country>('country');

const initials = country?.initials.toLowerCase() ?? 'cl';

const {
  errorMessage,
  meta,
  handleChange,
} = useField(props.name, undefined, {
  initialValue: props.initialValue,
});

function getAddressData(addressData: GoogleAutocompleteAddressData) {
  googleAutocompleteAddressData.value = addressData;
  handleChange(addressData);
  emit('update:modelValue', addressData);
}

function resetInputIfNotSelected() {
  if (!googleAutocompleteAddressData.value) {
    googleAutocomplete.value?.clear();
    emit('update:modelValue', undefined);
  }
}

</script>
<template>
  <div
    class="flex flex-col space-y-1"
  >
    <label
      :for="name"
      class="text-sm font-normal text-gray-700"
    >
      {{ label }}
      <span
        v-if="optional"
        class="text-xs opacity-40"
      >
        ({{ $t('common.optional') }})
      </span>
      <span
        v-else
        class="text-xs text-gray-700"
      >
        *
      </span>
    </label>
    <vue-google-autocomplete
      :id="name"
      ref="googleAutocomplete"
      :name="name"
      :placeholder="label"
      class="h-10 w-full rounded border px-2 text-gray-700 outline-gray-700 placeholder:text-sm"
      :class="[{
        'border-error-600 placeholder:text-red-300': !!errorMessage && meta.touched,
        'border-gray-300': !errorMessage,
      }]"
      :country="[initials]"
      :fields="['address_components', 'geometry']"
      @placechanged="getAddressData"
      @blur="resetInputIfNotSelected"
    />
  </div>
</template>
