<script setup lang="ts">
import { computed } from 'vue';

import useFamiliesWithIncludedCoupons from '@/composables/useFamiliesWithIncludedCoupons';
import type { FirstMainContent } from '@/types/first-main-content';
import familyIconSrc from '@/utils/family-icon-src';

interface Props {
  firstMainContent: FirstMainContent;
}

const props = defineProps<Props>();
const { families, hasFamilies } = useFamiliesWithIncludedCoupons(props.firstMainContent.families);
const cardsCount = computed(() => families.length);
</script>
<template>
  <div
    v-if="hasFamilies"
    id="main-content"
    class="mx-auto w-full px-6 py-14 sm:px-16 md:max-w-screen-2xl"
  >
    <base-swipeable-cards
      :title="firstMainContent.title ?? $t('sections.mainContents.shared.title')"
      :description="firstMainContent.description ?? $t('sections.mainContents.shared.description')"
      :cards-count="cardsCount"
    >
      <template #cards>
        <base-card
          v-for="family in families"
          :key="family.id"
          :image-url="family.resizedImageUrl || family.imageUrl"
          :resized-webp-image-url="family.resizedWebpImageUrl"
          :image-blurhash="family.imageBlurhash"
          :title="family.name"
          title-size="large"
          :description="family.description"
          :icon-src="familyIconSrc(family)"
          :icon-color="firstMainContent.iconColor"
          :href="family.showPath"
        />
      </template>
    </base-swipeable-cards>
  </div>
</template>
