import { ref } from 'vue';

import type { LoyaltyInfoModalNames } from '@/types/loyalty-info-modal-names';
import type { LoyaltyInfoModalsState } from '@/types/loyalty-info-modals-state';

export default function useLoyaltyInfoModals() {
  const loyaltyInfoModalsState = ref<LoyaltyInfoModalsState>({});
  function openLoyaltyModal(modalName: LoyaltyInfoModalNames) {
    loyaltyInfoModalsState.value = {
      [modalName]: true,
    };
  }

  function closeLoyaltyInfoModals() {
    loyaltyInfoModalsState.value = {};
  }

  return { loyaltyInfoModalsState, openLoyaltyModal, closeLoyaltyInfoModals };
}
