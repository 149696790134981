<script setup lang="ts">
import { computed } from 'vue';
import { useMq } from 'vue3-mq';

import type { Service } from '@/types/service';

const mq = useMq();

defineProps<{
  service: Service;
}>();

const iconSize = computed(() => (mq.smPlus ? 'md' : 'sm'));
</script>

<template>
  <div class="relative flex h-80 w-fit flex-col justify-end p-4 shadow-lg sm:h-[35rem] sm:p-6">
    <base-image
      v-if="service.imageUrl"
      class="left-0 top-0 -z-20 size-full overflow-hidden"
      img-styles="object-cover w-full h-full aspect-square sm:aspect-16/9"
      :src="service.resizedImageUrl || service.imageUrl"
      :webp-src="service.resizedWebpImageUrl"
      :blurhash="service.imageBlurhash"
      absolute
      :alt="$t('services.fields.imageAltText', { name: service.name })"
    />
    <div class="flex h-full items-end">
      <div class="flex h-1/2 items-end text-white">
        <div class="max-h-full w-40 bg-white/80 p-2 sm:w-80 sm:p-6">
          <div class="mb-4 line-clamp-2 text-sm font-semibold text-gray-900 sm:text-xl">
            {{ service.name }}
          </div>
          <base-rich-text
            class="line-clamp-5 text-left text-xs text-gray-600 sm:line-clamp-6 sm:text-sm"
            :text="service.description"
          />
        </div>
        <base-service-select
          is="base-link"
          :service="service"
          theme="primary"
          icon-file-name="arrow-right"
          :icon-size="iconSize"
          class="bg-primary p-1.5 text-white hover:text-white sm:p-3"
        />
      </div>
    </div>
  </div>
</template>
