<script setup lang="ts">
import { inject, type Ref } from 'vue';

import useSiteVariables from '@/composables/useSiteVariables';
import type { User } from '@/types/user';
import formatNumber from '@/utils/format-number';
import getUserInitials from '@/utils/get-user-initials';

interface Props {
  currentUser: User;
}

const props = defineProps<Props>();
const INITIALS_LENGTH = 2;
const userInitials = getUserInitials(props.currentUser, INITIALS_LENGTH);
const points = inject<Ref<number | undefined>>('points');
const units = inject<string | undefined>('units');
const { hasLoyalty } = useSiteVariables();

</script>

<template>
  <div class="flex flex-row items-center">
    <div class="rounded-full bg-white p-0.5">
      <div class="flex size-6 items-center justify-center rounded-full bg-primary/20 text-xs font-normal text-primary/90">
        {{ userInitials }}
      </div>
    </div>
    <div class="flex flex-col items-start">
      <span class="ml-2 text-xs font-semibold sm:text-sm">
        {{ $t('sections.navbars.shared.hello', { username: currentUser.firstName }) }}
      </span>
      <span
        v-if="hasLoyalty"
        class="ml-2 text-xs"
      >
        {{ `${formatNumber(points)} ${units}` }}
      </span>
      <span
        v-else
        class="ml-2 text-xs"
      >
        {{ currentUser.email }}
      </span>
    </div>
  </div>
</template>
