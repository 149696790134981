import { notify } from '@kyvg/vue3-notification';
import { camelize } from 'humps';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

export interface NotificationMessage {
  message: string;
  type: 'warn' | 'success' | 'error' | 'info';
}

export default function useNotifications(
  notice: NotificationMessage,
  showOnMount?: boolean,
) {
  const { t } = useI18n();
  const camelizedMessage = camelize(notice.message);
  const title = t(`common.notifications.titles.${notice.type}`);

  function showNotification() {
    notify({
      title,
      text: t(`common.notifications.messages.${camelizedMessage}`),
      type: notice.type,
    });
  }

  if (showOnMount) {
    onMounted(() => {
      showNotification();
    });
  }
}
