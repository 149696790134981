<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useFamilyServicesFilters from '@/composables/useFamilyServicesFilters';
import type { ThirdMainContent } from '@/types/third-main-content';

import PaginatedServices from './paginated-services.vue';
import ThirdMainContentServiceCard from './third-main-content-service-card.vue';
import familyIconSrc from '../../../utils/family-icon-src';

interface Props {
  thirdMainContent: ThirdMainContent;
}

const props = defineProps<Props>();

const { t } = useI18n();

const families = computed(() => props.thirdMainContent.families);
const {
  couponFamily,
  familiesWithAllServices,
  hasFamiliesOrCoupons,
  selectedFamilyIndex,
  selectFamily,
  services,
} = useFamilyServicesFilters(families);

const showFilters = computed(() => !props.thirdMainContent.hideFilters);

const iconColor = computed(() => props.thirdMainContent.iconColor || 'primary');
const selectedFamilyColorClass = computed(() => {
  const colorClassByIconColor = {
    primary: 'text-primary',
    secondary: 'text-secondary',
  };

  return colorClassByIconColor[iconColor.value];
});
</script>

<template>
  <div
    v-if="hasFamiliesOrCoupons"
    id="main-content"
    class="mx-auto flex w-full flex-col px-6 pb-7 pt-14 sm:px-16 sm:py-24 md:max-w-screen-2xl"
  >
    <h2 class="mb-12 text-center text-xl font-medium sm:px-0 sm:text-5xl">
      {{ props.thirdMainContent.title ?? $t('sections.mainContents.shared.title') }}
    </h2>
    <div
      v-if="showFilters"
      class="mx-auto mb-8 flex max-w-full flex-wrap justify-center gap-8 sm:mb-24 sm:w-full md:justify-around"
    >
      <!-- eslint-disable tailwindcss/no-custom-classname -->
      <button
        v-for="(family, index) in familiesWithAllServices"
        :key="family.id"
        class="flex max-w-20 flex-col items-center gap-2"
        :class="{
          'text-gray-700': selectedFamilyIndex !== index,
          [selectedFamilyColorClass]: selectedFamilyIndex === index,
        }"
        :data-testid="`${family.name}-family-button`"
        @click="selectFamily(index)"
      >
        <base-svg
          v-bind="familyIconSrc(family)"
          class="size-10 fill-current sm:size-14"
        />
        <span class="text-xs sm:text-base">
          {{ family.name }}
        </span>
      </button>
      <a
        v-if="couponFamily"
        class="flex max-w-20 flex-col items-center gap-2 text-gray-700"
        :data-testid="`${couponFamily.name}-family-button`"
        :href="couponFamily.showPath"
      >
        <base-svg
          v-bind="familyIconSrc(couponFamily)"
          class="size-10 fill-current sm:size-16"
        />
        <span class="text-xs sm:text-base">
          {{ t('coupons.titleShort') }}
        </span>
      </a>
      <!-- eslint-enable tailwindcss/no-custom-classname -->
    </div>
    <paginated-services
      v-slot="{ currentPageServices }"
      :services="services"
    >
      <third-main-content-service-card
        v-for="service in currentPageServices"
        :key="service.id"
        :service="service"
      />
    </paginated-services>
  </div>
</template>
