<script setup lang="ts">
import { computed } from 'vue';

import useFamilyServicesFilters from '@/composables/useFamilyServicesFilters';
import type { SeventhMainContent } from '@/types/seventh-main-content';
import familyIconSrc from '@/utils/family-icon-src';

import SeventhMainContentServiceCard from './seventh-main-content-service-card.vue';

interface Props {
  seventhMainContent: SeventhMainContent;
}

const props = defineProps<Props>();

const iconColor = props.seventhMainContent.iconColor ?? 'primary';

const families = computed(() => props.seventhMainContent.families);
const showFilters = computed(() => !props.seventhMainContent.hideFilters);

const {
  couponFamily,
  familiesWithAllServices,
  hasFamiliesOrCoupons,
  selectedFamilyIndex,
  selectFamily,
} = useFamilyServicesFilters(families);

function isFamilySelected(index: number) {
  return selectedFamilyIndex.value === index;
}

const selectedFamilyClass = computed(() => {
  const colorClassByIconColor = {
    primary: 'bg-primary/10 text-primary',
    secondary: 'bg-secondary/10 text-secondary',
  };

  return colorClassByIconColor[iconColor];
});

const selectedFamilies = computed(() => {
  if (selectedFamilyIndex.value) {
    return [families.value[selectedFamilyIndex.value - 1]];
  }

  return families.value;
});
</script>
<template>
  <main
    v-if="hasFamiliesOrCoupons"
    id="main-content"
    class="mx-auto mb-7 mt-12 flex w-full flex-col px-6 sm:mt-24 sm:px-16 md:max-w-screen-2xl"
  >
    <h2
      class="mb-10 text-center text-xl font-medium sm:text-5xl"
    >
      {{ seventhMainContent.title ?? $t('sections.mainContents.shared.title') }}
    </h2>
    <div
      v-if="showFilters"
      class="mb-10 flex justify-center"
    >
      <ul class="flex gap-x-6 overflow-x-auto text-sm text-gray-600 sm:text-base [&::-webkit-scrollbar]:hidden">
        <li
          v-for="(family, index) in familiesWithAllServices"
          :key="family.id"
          class="flex cursor-pointer gap-x-1 whitespace-nowrap bg-gray-100 px-3 py-1.5"
          :class="{ [selectedFamilyClass]: isFamilySelected(index) }"
          :data-testid="`${family.name}-family-button`"
        >
          <base-svg
            v-bind="familyIconSrc(family)"
            class="size-5 fill-current sm:size-6"
          />
          <a
            href="#main-content"
            class="inline-block"
            @click.stop="selectFamily(index)"
          >
            {{ family.name }}
          </a>
        </li>
        <li
          v-if="couponFamily"
          class="flex cursor-pointer gap-x-1 whitespace-nowrap bg-gray-100 px-3 py-1.5"
        >
          <base-svg
            v-bind="familyIconSrc(couponFamily)"
            class="size-5 fill-current sm:size-6"
          />
          <a
            :href="couponFamily.showPath"
            class="inline-block"
            :data-testid="`${couponFamily.name}-family-button`"
          >
            {{ $t('coupons.titleShort') }}
          </a>
        </li>
      </ul>
    </div>

    <section
      v-for="family in selectedFamilies"
      :key="`family-${family.id}`"
      class="mb-20"
    >
      <header class="mb-6 text-gray-600">
        <h3
          data-testid="family-name"
          class="mb-2 text-2xl font-bold text-gray-700 sm:mb-6 sm:text-4xl"
        >
          {{ family.name }}
        </h3>
        <p class="text-sm sm:text-base">
          {{ family.description }}
        </p>
      </header>
      <section
        class="flex gap-4 overflow-x-auto sm:gap-8"
      >
        <seventh-main-content-service-card
          v-for="service in family.allIncludedServices"
          :key="service.id"
          :service="service"
        />
      </section>
    </section>
  </main>
</template>
