<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import type { Values } from '@/api/service';
import type { Service } from '@/types/service';

type AddressValues = Values & {
  route: string
}

type GroupedDataEntry = {
  inputName: string;
  value: string;
  order: number;
};

type GroupedData = Record<string, GroupedDataEntry[]>;

const props = defineProps<{
  service: Service;
  formValues: Values;
}>();

const { t } = useI18n({});

function isAddressInput(inputName: string, value: unknown): value is AddressValues {
  return inputName === 'address' && typeof value === 'object' && !!((value as AddressValues).route);
}

function addToGroupedData(groupedData: GroupedData, kind: string, entry: GroupedDataEntry) {
  if (!entry.value) return;

  if (!groupedData[kind]) {
    groupedData[kind] = [];
  }

  groupedData[kind].push(entry);
  groupedData[kind].sort((a, b) => a.order - b.order);
}

function getTranslatedNameAndOrder(inputName: string, kind: string): { translatedName: string; order: number } {
  const input = props.service.inputs?.find(i => i.name === inputName);

  const translatedName =
    input && input.customName ? input.customName : t(`serviceAssistance.success.labels.${kind}.${inputName}`);
  const order = input?.order || 0;

  return { translatedName, order };
}

function translateAndGroupFormData(formValues: Values) {
  const groupedFormData: GroupedData = {};

  Object.entries(formValues).forEach(([inputName, value]) => {
    const [name, kind] = inputName.split('-');
    const finalValue = isAddressInput(name, value) ? value.route : value;
    const { translatedName, order } = getTranslatedNameAndOrder(name, kind);
    addToGroupedData(groupedFormData, kind, { inputName: translatedName, value: finalValue, order });
  });

  return groupedFormData;
}

const formDataByKind = computed(() => translateAndGroupFormData(props.formValues));

</script>

<template>
  <div class="flex flex-col items-center bg-gray-50 px-6 py-8">
    <div class="mb-3 text-center">
      <span class="text-3xl font-medium">{{ $t('serviceAssistance.success.title.firstPart') }}</span>
      <span class="text-3xl font-medium text-primary">{{ $t('serviceAssistance.success.title.secondPart') }}</span>
    </div>

    <h2 class="text-center text-xl font-normal text-gray-500">
      {{ $t('serviceAssistance.success.thanks') }}
    </h2>

    <div class="w-full max-w-xl pt-4">
      <div class="rounded-2xl bg-white px-8 py-9 shadow-mok-md">
        <div class="flex w-full justify-between">
          <p class="text-xs font-bold sm:text-sm">
            {{ $t('serviceAssistance.success.requestedService') }}
          </p>
          <p class="text-right text-xs sm:text-sm">
            {{ service.name }}
          </p>
        </div>
        <div
          class="my-4 h-px w-full bg-gray-300"
        />

        <div
          v-for="(inputs, groupName, index) in formDataByKind"
          :key="groupName"
          class="flex w-full flex-col"
        >
          <p class="pb-2 text-xs font-bold sm:text-sm">
            {{ $t(`serviceFormInfo.${groupName}.title`) }}
          </p>
          <div
            v-for="input in inputs"
            :key="input.inputName"
          >
            <div class="flex justify-between pb-3 text-gray-700">
              <p class="text-xs font-normal sm:text-sm">
                {{ input.inputName }}
              </p>
              <p class="text-xs font-medium sm:text-sm">
                {{ input.value }}
              </p>
            </div>
          </div>

          <div
            v-if="index < Object.keys(formDataByKind).length - 1"
            class="my-4 h-px w-full bg-gray-300"
          />
        </div>
      </div>

      <div class="flex w-full justify-end py-3">
        <base-button
          type="button"
          theme="primary-button"
          href="/"
        >
          {{ $t('common.close') }}
        </base-button>
      </div>
    </div>
  </div>
</template>
