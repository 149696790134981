<script setup lang="ts">
import { computed } from 'vue';

import loyaltyInfoModalsContent from '@/values/loyalty-info-modals-content.json';

type ContentKeys = keyof typeof loyaltyInfoModalsContent;

interface Props {
  contentKey: ContentKeys;
}
const props = defineProps<Props>();
const content = computed(() => loyaltyInfoModalsContent[props.contentKey]);

</script>
<template>
  <div class="mx-auto flex w-full flex-col items-center py-20 text-center md:hidden">
    <div class="flex size-24 items-center justify-center rounded-full bg-secondary/10">
      <base-svg
        :path="`${content.icon}`"
        class="w-14 fill-secondary/50 stroke-secondary stroke-2"
      />
    </div>
    <p class="mt-4 text-xl font-bold">
      {{ $t(content.title) }}
    </p>
    <p class="mt-4 text-base text-gray-800">
      {{ $t(content.description) }}
    </p>
  </div>
</template>
