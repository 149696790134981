<script setup lang="ts">
import { computed } from 'vue';

interface Props {
  totalPages: number;
  currentPage: number;
}
const props = defineProps<Props>();

interface Emits {
  (e: 'page-changed', page: number): void;
}
defineEmits<Emits>();

const ellipsis = '...';

const unselectedClass = 'bg-transparent text-gray-500 hover:bg-primary/40 hover:text-white';
const selectedClass = 'bg-primary text-white';
const ellipsisClass = 'bg-transparent text-gray-500';
function buttonClassBySelectionAndEllipsis(page: number | string) {
  if (page === ellipsis) {
    return ellipsisClass;
  }

  return page === props.currentPage ? selectedClass : unselectedClass;
}

/* eslint-disable no-magic-numbers */
const middlePageDelta = 1;
const extremeAndPotentialEllipsis = 2;
const middlePages = 2 * middlePageDelta + 1;
const maxPagesToShow = middlePages + extremeAndPotentialEllipsis * 2;
const maxPagesToShowWhenCloseToExtreme = maxPagesToShow - extremeAndPotentialEllipsis;
const deltaFromExtremeWithoutEllipsis = middlePageDelta + extremeAndPotentialEllipsis;
const firstPage = 1;
const lastPage = computed(() => props.totalPages);
const hasLessPagesThanMax = computed(() => props.totalPages <= maxPagesToShow);
const isCloseToBeginning = computed(() => props.currentPage <= firstPage + deltaFromExtremeWithoutEllipsis);
const isCloseToEnd = computed(() => props.currentPage >= lastPage.value - deltaFromExtremeWithoutEllipsis);
const pagesToShowWithEllipses = computed(() => {
  if (hasLessPagesThanMax.value) {
    return Array.from({ length: props.totalPages }, (_, i) => i + 1);
  } else if (isCloseToBeginning.value) {
    const consecutiveNumbers = Array.from({ length: maxPagesToShowWhenCloseToExtreme }, (_, i) => i + 1);

    return [...consecutiveNumbers, ellipsis, props.totalPages];
  } else if (isCloseToEnd.value) {
    const consecutiveNumbers = Array.from(
      { length: maxPagesToShowWhenCloseToExtreme },
      (_, i) => props.totalPages - (maxPagesToShowWhenCloseToExtreme - 1) + i,
    );

    return [1, ellipsis, ...consecutiveNumbers];
  }

  const consecutiveNumbers = Array.from(
    { length: middlePageDelta * 2 + 1 }, (_, i) => props.currentPage - middlePageDelta + i,
  );

  return [1, ellipsis, ...consecutiveNumbers, ellipsis, props.totalPages];
});
/* eslint-enable no-magic-numbers */
</script>

<template>
  <div
    v-if="props.totalPages > 1"
    class="flex rounded-lg border border-gray-100 p-2 sm:p-4"
  >
    <button
      data-testid="previous-page-button"
      class="mr-2.5 flex size-8 items-center justify-center rounded-lg border border-primary text-primary disabled:border-gray-400 disabled:text-gray-400 sm:size-14"
      :disabled="currentPage === 1"
      @click="$emit('page-changed', currentPage - 1)"
    >
      <base-svg
        name="chevron-right"
        class="size-3 rotate-180 stroke-current sm:size-4"
      />
    </button>
    <component
      :is="page === ellipsis ? 'div' : 'button'"
      v-for="(page, index) in pagesToShowWithEllipses"
      :key="page"
      :data-testid="`page-${index}`"
      class="flex size-8 items-center justify-center rounded-lg text-xs sm:size-14 sm:text-base"
      :class="buttonClassBySelectionAndEllipsis(page)"
      @click="page === ellipsis ? undefined : $emit('page-changed', page as number)"
    >
      {{ page }}
    </component>
    <button
      data-testid="next-page-button"
      class="ml-2.5 flex size-8 items-center justify-center rounded-lg border border-primary text-primary disabled:border-gray-400 disabled:text-gray-400 sm:size-14"
      :disabled="currentPage === totalPages"
      @click="$emit('page-changed', currentPage + 1)"
    >
      <base-svg
        name="chevron-right"
        class="size-3 stroke-current sm:size-4"
      />
    </button>
  </div>
</template>
