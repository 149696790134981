import { computed, ref, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';

import eventsApi from '@/api/events';
import type { Category } from '@/types/category';
import familyIconSrc from '@/utils/family-icon-src';

import { useCouponFamily } from './useFamiliesWithIncludedCoupons';

// eslint-disable-next-line max-statements
export default function useFamilyServicesFilters(families: Ref<Category[]>) {
  const { t } = useI18n();
  const familiesCount = computed(() => families.value.length);

  const minFamiliesCountToShowAllServicesFamily = 2;
  const allServicesFamily = computed(() => {
    if (familiesCount.value < minFamiliesCountToShowAllServicesFamily) return undefined;

    return {
      id: -1,
      name: t('sections.mainContents.shared.allServices'),
      icon: 'star',
      allIncludedServices: families.value.flatMap((family) =>
        family.allIncludedServices?.map((service) => ({
          ...service,
          familyName: family.name,
          familyIconSrc: familyIconSrc(family),
        })) || [],
      ),
    };
  });
  const familiesWithAllServices = computed(() => {
    if (allServicesFamily.value) return [allServicesFamily.value, ...families.value];

    return families.value;
  });

  const { couponFamily } = useCouponFamily();
  const hasFamiliesOrCoupons = computed(() => familiesCount.value + (couponFamily ? 1 : 0) > 0);

  const selectedFamilyIndex = ref(0);

  function sendEvent(index: number) {
    const familyId = familiesWithAllServices.value[index].id;
    if (familyId < 0) {
      eventsApi.selectAllFamilies();
    } else if (familyId !== 0) {
      eventsApi.selectFamily(familyId);
    }
  }

  function selectFamily(index: number, withEvent = true) {
    if (withEvent) {
      sendEvent(index);
    }
    selectedFamilyIndex.value = index;
    sessionStorage.setItem('selectedFamilyIndex', index.toString());
  }

  const selectedFamily = computed(() => familiesWithAllServices.value[selectedFamilyIndex.value]);
  const services = computed(() => selectedFamily.value.allIncludedServices || []);

  const selectedFamilyIndexQueryParam = new URLSearchParams(window.location.search).get('family_index');
  const selectedFamilyIndexParamParsed = Number(selectedFamilyIndexQueryParam);

  function isValidIndex(index: number): boolean {
    return index >= 0 && index < familiesWithAllServices.value.length;
  }

  if (isValidIndex(selectedFamilyIndexParamParsed)) {
    selectFamily(selectedFamilyIndexParamParsed, false);
  }

  return {
    allServicesFamily,
    couponFamily,
    familiesWithAllServices,
    hasFamiliesOrCoupons,
    selectedFamilyIndex,
    selectFamily,
    services,
  };
}
