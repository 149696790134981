<script setup lang="ts">
import { computed, useAttrs, type ComputedRef, type Component } from 'vue';

type Variant = 'primary' | 'secondary' | 'link';
type Color = 'primary' | 'purple';
type Tone = 'dark' | 'light';

type VariantClasses = {
  [key in Variant]: string;
};

type ColorClasses = {
  [key in Color]: string;
};

type ToneIntensities = {
  [key in Tone]: number;
};

export type Props = {
  variant?: Variant;
  size?: 'small' | 'medium' | 'large';
  label?: string;
  icon?: Component;
  iconPosition?: 'left' | 'right';
  loading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  color?: Color;
  tone?: Tone;
};

const props = withDefaults(
  defineProps<Props>(),
  {
    variant: 'primary',
    tone: 'dark',
    color: 'primary',
    size: 'medium',
    label: undefined,
    icon: undefined,
    iconPosition: 'left',
    loading: false,
    disabled: false,
    fullWidth: false,
  },
);

const attrs = useAttrs();
const currentTag = computed(() => {
  const hasHrefAndIsNotDisabled = attrs.href && !props.disabled;

  return hasHrefAndIsNotDisabled ? 'a' : 'button';
});

/* Force tailwind to include the possible classes in bundle */
// @ts-expect-error unused variable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const colorClasses : ColorClasses = {
  primary: `bg-primary-700 bg-primary-400 border-primary-700 border-primary-400
    hover:bg-primary-600 hover:bg-primary-600 hover:border-primary-600
    hover:border-primary-300 text-primary-700 text-primary-600 text-primary-400`,
  purple: `bg-purple-700 bg-purple-400 border-purple-700 border-purple-400
    hover:bg-purple-600 hover:bg-purple-600 hover:border-purple-600
    hover:border-purple-300 text-purple-700 text-primary-600 text-purple-400`,
};

const toneIntensities : ToneIntensities = {
  dark: 700,
  light: 400,
};
const toneIntensity = computed(() => toneIntensities[props.tone]);
// eslint-disable-next-line no-magic-numbers
const lowerToneIntensity = computed(() => toneIntensity.value - 100);

const buttonVariantClasses : ComputedRef<VariantClasses> = computed(() => ({
  primary: `border text-white bg-${props.color}-${toneIntensity.value} border-${props.color}-${toneIntensity.value}
    hover:bg-${props.color}-${lowerToneIntensity.value} hover:border-${props.color}-${lowerToneIntensity.value}
    disabled:bg-gray-200 disabled:text-gray-400 disabled:border-gray-200 `,
  secondary: `bg-white border border-${props.color}-${toneIntensity.value} text-${props.color}-${toneIntensity.value}
    hover:border-${props.color}-${lowerToneIntensity.value} hover:text-${props.color}-${toneIntensity.value}
    disabled:border-gray-400 disabled:text-gray-400`,
  link: `text-${props.color}-${lowerToneIntensity.value} hover:text-${props.color}-600`,
}));

const buttonSizeStyles = computed(() => ({
  small: props.variant === 'link' ? 'gap-x-1' : 'rounded-md gap-x-1 py-2',
  medium: props.variant === 'link' ? 'gap-x-2' : 'rounded-lg gap-x-2 py-3',
  large: props.variant === 'link' ? 'gap-x-2' : 'rounded-xl gap-x-3 py-4',
}));

const buttonPaddingsForLabel = {
  small: 'px-3',
  medium: 'px-6',
  large: 'px-10',
};

const buttonPaddingsForIcon = {
  small: 'px-2',
  medium: 'px-3',
  large: 'px-4',
};

const iconSizeStyles = {
  small: 'h-3 w-3',
  medium: 'h-4 w-4',
  large: 'h-6 w-6',
};

const labelSizeStyles = {
  small: 'text-2xs leading-3',
  medium: 'text-sm leading-4',
  large: 'text-base leading-6',
};

const buttonPaddingsForLabelAndIcon = {
  left: {
    small: 'pl-2 pr-3',
    medium: 'pl-4 pr-6',
    large: 'pl-4 pr-6',
  },
  right: {
    small: 'pr-2 pl-3',
    medium: 'pr-4 pl-6',
    large: 'pr-4 pl-6',
  },
};

const buttonVariantStyle = computed(() => buttonVariantClasses.value[props.variant]);
const buttonSizeStyle = computed(() => buttonSizeStyles.value[props.size]);
const buttonPaddingStyle = computed(() => {
  if (props.variant === 'link') {
    return '';
  }

  if (props.icon && props.label) {
    return buttonPaddingsForLabelAndIcon[props.iconPosition][props.size];
  } else if (props.label) {
    return buttonPaddingsForLabel[props.size];
  }

  return buttonPaddingsForIcon[props.size];
});

const iconSizeStyle = computed(() => iconSizeStyles[props.size]);
const labelSizeStyle = computed(() => labelSizeStyles[props.size]);
const iconAtRight = computed(() => props.iconPosition === 'right');

</script>
<template>
  <component
    :is="currentTag"
    :disabled="disabled"
    v-bind="attrs"
    class="relative inline-flex h-min items-center justify-center transition-colors duration-150 ease-linear"
    :class="[
      buttonVariantStyle,
      buttonSizeStyle,
      buttonPaddingStyle,
      { 'w-full grow': fullWidth,
        'flex-row-reverse': iconAtRight }
    ]"
  >
    <component
      :is="icon"
      v-if="icon"
      class="text-current"
      :class="[iconSizeStyle, { 'invisible': loading }]"
    />
    <span
      v-if="label"
      data-testid="label"
      class="font-medium"
      :class="[labelSizeStyle, { 'invisible': loading }]"
    >
      {{ label }}
    </span>
    <base-svg
      v-if="loading"
      name="loading"
      class="absolute h-6 animate-spin stroke-current"
      :class="iconSizeStyle"
    />
  </component>
</template>
