<script setup lang="ts">
import { camelize } from 'humps';
import { useForm } from 'vee-validate';
import { reactive, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useMutation } from 'vue-query';
import { object, string } from 'yup';

import userApi from '@/api/user';
import useVisibilityTimeout from '@/composables/useVisibilityTimeout';
import type { User } from '@/types/user';
import type { UserEditFormData } from '@/types/user-edit-form-data';
import getUserInitials from '@/utils/get-user-initials';
import injectStrict from '@/utils/inject-strict';
import nationalIdUtils from '@/utils/national-id-utils';

interface Props {
  countryNationalIdName: string;
  mobilePhonePattern: string;
  countryFlagIcon?: string;
  phonePrefix: string;
  userDocumentIdentifierKind: string;
  requireNationalId?: boolean;
  countryCode: string;
}

const props = withDefaults(defineProps<Props>(), {
  requireNationalId: false,
  countryFlagIcon: undefined,
});
const currentUser = ref(injectStrict<User>('currentUser'));

const userInitialsLength = 2;
const userInitials = computed(() => getUserInitials(currentUser.value, userInitialsLength));

const initialUserData = reactive<UserEditFormData>({
  firstName: currentUser.value.firstName,
  lastName: currentUser.value.lastName,
  nationalId: currentUser.value.nationalId,
  mobilePhoneNumber: currentUser.value.mobilePhoneNumber,
});

const { t } = useI18n();

const { format: formatNationalId, validate: validateNationalId } = nationalIdUtils(props.countryCode);

function nationalIdValidator(value: string) {
  return validateNationalId ? validateNationalId(value) : true;
}

function nationalIdFormatter(value: string) {
  return formatNationalId ? formatNationalId(value) : value;
}

function generateValidationSchema() {
  const schema = {
    firstName: string().required().label(t('userEditForm.firstName')),
    lastName: string().required().label(t('userEditForm.lastName')),
    mobilePhoneNumber: string().required().matches(
      new RegExp(`^${props.mobilePhonePattern}$`), t('userEditForm.mobilePhoneNumberValidation'),
    )
      .label(t('userEditForm.mobilePhoneNumber')),
    nationalId: undefined,

  };

  if (props.requireNationalId) {
    schema.nationalId = string().required().test(nationalIdValidator)
      .label(props.countryNationalIdName);
  }

  return object().shape(schema);
}
const validationSchema = generateValidationSchema();

const { resetForm, values: userData, handleSubmit } = useForm<UserEditFormData>(
  {
    initialValues: { ...initialUserData },
    validationSchema,
    validateOnMount: true,
  },
);

const {
  mutate: updateUserMutation,
  isLoading,
  isError,
  isSuccess,
} = useMutation(
  () => userApi.update(userData), {
    onSuccess: async (data) => {
      currentUser.value = data;
    },
  },
);

const { isVisible: isSuccessMessageVisible } = useVisibilityTimeout(
  { condition: isSuccess },
);
const { isVisible: isErrorMessageVisible } = useVisibilityTimeout(
  { condition: isError },
);

const updateUser = handleSubmit(() => {
  updateUserMutation();
});

const documentIdentifierName = computed(() => camelize(props.userDocumentIdentifierKind));

function isDocumentIdentifier(inputName: string) {
  return inputName === documentIdentifierName.value;
}

const isNationalIdDisabled = computed(() => {
  const hasNationalIdValidation = !!validateNationalId;

  return !hasNationalIdValidation || isDocumentIdentifier('nationalId');
});

const isMobilePhoneNumberDisabled = computed(() => (isDocumentIdentifier('mobilePhoneNumber')));

function disabledFieldLabel(fieldName: string) {
  const translatedFieldName = t(`userEditForm.${fieldName}`).toLowerCase();

  return t('userEditForm.disabledField', { fieldName: translatedFieldName });
}
const nationalIdDisabledLabel = computed(() => (
  t('userEditForm.disabledField', { fieldName: props.countryNationalIdName })),
);

function handleNationalIdChange(input: HTMLInputElement) {
  input.value = nationalIdFormatter(input.value);
}

function redirectBack() {
  window.history.back();
}

const initialNationalId = computed(() => {
  if (currentUser.value.nationalId && formatNationalId) {
    return formatNationalId(currentUser.value?.nationalId);
  }

  return currentUser.value.nationalId;
});

</script>
<template>
  <div class="flex w-full flex-col gap-y-8 self-center px-6 py-14 sm:container sm:mx-auto sm:grid sm:grid-cols-6 sm:px-0">
    <div class="sm:col-span-1">
      <base-link
        theme="gray"
        icon-file-name="back"
        icon-position="left"
        icon-size="sm"
        :label="$t('common.back')"
        @click="redirectBack"
      />
    </div>
    <div class="col-span-4 flex justify-center">
      <div class="mr-6 flex size-12 items-center justify-center rounded-full border-2 border-primary/50 bg-primary/20 text-lg font-medium text-primary/90 sm:size-16 sm:text-2xl">
        {{ userInitials }}
      </div>
      <div class="flex flex-col justify-items-center border-l-2 border-gray-300 pl-6">
        <p class="text-lg font-medium text-black sm:text-xl">
          {{ currentUser.firstName }}  {{ currentUser.lastName }}
        </p>
        <div class="flex items-center gap-x-2">
          <p class="text-xs text-gray-500 sm:text-sm">
            {{ currentUser.email }}
          </p>
          <base-tooltip
            :text="disabledFieldLabel('email')"
          >
            <base-svg
              name="info-circle"
              class="mx-1 my-1.5 size-4 stroke-gray-500"
            />
          </base-tooltip>
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <base-badge
        v-if="isErrorMessageVisible"
        label="userEditForm.error"
        color="error"
        theme="dark"
      />
      <base-badge
        v-if="isSuccessMessageVisible"
        label="userEditForm.success"
        color="success"
        theme="dark"
      />
    </div>
    <div class="flex flex-col gap-y-8 sm:col-span-4 sm:col-start-2">
      <div class="flex flex-col">
        <h1 class="text-lg font-semibold  text-gray-900">
          {{ $t('userEditForm.profile') }}
        </h1>
        <h2 class="text-sm text-gray-500">
          {{ $t('userEditForm.editProfileInfo') }}
        </h2>
      </div>
      <form
        id="user-edit-form"
        class="grid gap-x-8 gap-y-6 sm:grid-cols-2"
      >
        <base-input
          :label="$t('userEditForm.firstName')"
          data-testid="first-name-input"
          name="firstName"
          type="text"
          :initial-value="currentUser.firstName"
        />
        <base-input
          :label="$t('userEditForm.lastName')"
          data-testid="last-name-input"
          name="lastName"
          type="text"
          :initial-value="currentUser.lastName"
        />
        <base-input
          v-if="requireNationalId"
          :label="countryNationalIdName"
          data-testid="national-id-input"
          name="nationalId"
          :disabled="isNationalIdDisabled"
          :disabled-description="nationalIdDisabledLabel"
          type="text"
          :initial-value="initialNationalId"
          @input="handleNationalIdChange($event.target)"
        />
        <base-phone-input
          :label="$t('userEditForm.mobilePhoneNumber')"
          data-testid="phone-number-input"
          name="mobilePhoneNumber"
          :disabled="isMobilePhoneNumberDisabled"
          :disabled-description="disabledFieldLabel('mobilePhoneNumber')"
          :initial-value="currentUser.mobilePhoneNumber"
          :prefix="phonePrefix"
          :flag="countryFlagIcon"
        />
        <div class="flex flex-col gap-x-8 gap-y-6 sm:col-span-2 sm:row-start-3 sm:flex sm:flex-row xl:col-span-1 xl:col-start-2 xl:gap-x-4">
          <base-button
            theme="primary-button-outline"
            type="button"
            class="w-full"
            @click="resetForm"
          >
            {{ $t('common.cancel') }}
          </base-button>
          <base-button
            theme="primary-button"
            type="button"
            class="w-full"
            :disabled="isLoading"
            @click="updateUser"
          >
            {{ $t('common.saveChanges') }}
          </base-button>
        </div>
      </form>
    </div>
  </div>
</template>
