<script setup lang="ts">
import { computed, useAttrs } from 'vue';

type BaseLinkTheme = 'white' | 'primary' | 'gray' | 'secondary';

type BaseLinkThemeStyles = {
  [key in BaseLinkTheme]:string;
}
interface Props {
  theme: BaseLinkTheme
  label: string;
  iconFileName?: string;
  iconPosition?: 'left' | 'right';
  iconSize?: 'sm' | 'md';
}

const props = withDefaults(
  defineProps<Props>(),
  {
    iconFileName: undefined,
    iconPosition: 'right',
    iconSize: 'md',
  },
);

const themeStyles: BaseLinkThemeStyles = {
  'white': 'text-white hover:text-white/70',
  'primary': 'text-primary hover:text-primary/70',
  'secondary': 'text-secondary hover:text-secondary/70',
  'gray': 'text-gray-700 hover:text-gray-700/70',
};

const iconSizeStyles = {
  sm: 'size-3',
  md: 'size-6',
};

const iconSizeStyle = computed(() => iconSizeStyles[props.iconSize]);

const attrs = useAttrs();
const currentTheme = computed(() => themeStyles[props.theme]);
const currentTag = computed(() => (attrs.href ? 'a' : 'button'));
const rightIcon = computed(() => props.iconFileName && (props.iconPosition === 'right'));
const leftIcon = computed(() => props.iconFileName && (props.iconPosition === 'left'));

</script>
<template>
  <component
    :is="currentTag"
    class="inline-flex h-min items-center justify-center gap-2 py-2 text-center"
    :class="currentTheme"
  >
    <base-svg
      v-if="leftIcon"
      :name="iconFileName"
      class="fill-current stroke-current"
      :class="iconSizeStyle"
    />

    {{ props.label }}

    <base-svg
      v-if="rightIcon"
      :name="iconFileName"
      class="fill-current stroke-current"
      :class="iconSizeStyle"
    />
  </component>
</template>
