<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useFamilyServicesFilters from '@/composables/useFamilyServicesFilters';
import type { EighthMainContent } from '@/types/eighth-main-content';
import familyIconSrc from '@/utils/family-icon-src';

import EighthMainContentServiceCard from './eighth-main-content-service-card.vue';
import PaginatedServices from './paginated-services.vue';

interface Props {
  eighthMainContent: EighthMainContent;
}

const props = defineProps<Props>();

const { t } = useI18n();

const families = computed(() => props.eighthMainContent.families);
const {
  couponFamily,
  familiesWithAllServices,
  hasFamiliesOrCoupons,
  selectedFamilyIndex,
  selectFamily,
  services,
} = useFamilyServicesFilters(families);

const showFilters = computed(() => !props.eighthMainContent.hideFilters);

const iconColor = computed(() => props.eighthMainContent.iconColor || 'primary');
const selectedFamilyColorClass = computed(() => {
  const colorClassByIconColor = {
    primary: 'text-primary border-primary',
    secondary: 'text-secondary border-secondary',
  };

  return colorClassByIconColor[iconColor.value];
});
</script>

<template>
  <div
    v-if="hasFamiliesOrCoupons"
    id="main-content"
    class="mx-auto flex w-full flex-col px-6 pb-7 pt-14 sm:px-16 sm:py-24 md:max-w-screen-2xl"
  >
    <h2 class="mb-12 text-center text-xl font-medium sm:px-0 sm:text-5xl">
      {{ props.eighthMainContent.title ?? $t('sections.mainContents.shared.title') }}
    </h2>
    <div
      v-if="showFilters"
      id="families-filter"
      class="mx-auto mb-6 flex h-auto w-full gap-4 overflow-x-auto p-2 sm:mb-16 sm:gap-6 sm:pb-4"
    >
      <!-- eslint-disable tailwindcss/no-custom-classname -->
      <button
        v-for="(family, index) in familiesWithAllServices"
        :key="family.id"
        class="flex min-h-14 w-20 min-w-20 flex-col items-center rounded-lg border p-2 first:ml-auto last:mr-auto sm:min-h-24 sm:w-32 sm:min-w-32 sm:px-5 sm:py-3"
        :class="{
          'border-gray-200 text-gray-700': selectedFamilyIndex !== index,
          [selectedFamilyColorClass]: selectedFamilyIndex === index,
        }"
        :data-testid="`${family.name}-family-button`"
        @click="selectFamily(index)"
      >
        <base-svg
          v-bind="familyIconSrc(family)"
          class="max-h-4 min-h-4 min-w-5 max-w-5 fill-current sm:max-h-8 sm:min-h-8 sm:min-w-8 sm:max-w-8"
          :class="{ [selectedFamilyColorClass]: index === selectedFamilyIndex }"
        />
        <div class="flex h-full flex-col justify-center pt-2">
          <span class="hyphens-auto text-xs sm:text-sm">
            {{ family.name }}
          </span>
        </div>
      </button>
      <a
        v-if="couponFamily"
        class="flex min-h-14 w-20 min-w-20 flex-col items-center rounded-lg border p-2 first:ml-auto last:mr-auto sm:min-h-24 sm:w-32 sm:min-w-32 sm:px-5 sm:py-3"
        :data-testid="`${couponFamily.name}-family-button`"
        :href="couponFamily.showPath"
      >
        <base-svg
          v-bind="familyIconSrc(couponFamily)"
          class="max-h-4 min-h-4 min-w-5 max-w-5 fill-current sm:max-h-8 sm:min-h-8 sm:min-w-8 sm:max-w-8"
        />
        <div class="flex h-full flex-col justify-center pt-2">
          <span class="text-xs sm:text-sm">
            {{ t('coupons.titleShort') }}
          </span>
        </div>
      </a>
    </div>
    <paginated-services
      v-slot="{ currentPageServices }"
      :services="services"
    >
      <eighth-main-content-service-card
        v-for="service in currentPageServices"
        :key="service.id"
        :service="service"
      />
    </paginated-services>
  </div>
</template>

<style scoped>
#families-filter::-webkit-scrollbar {
  height: 4px;
}

@media (min-width: 640px) {
  #families-filter::-webkit-scrollbar {
    height: 8px;
  }
}

#families-filter::-webkit-scrollbar-track {
  background: #E7E5E4;
  border-radius: 8px;
}

#families-filter::-webkit-scrollbar-thumb {
  background: rgba(var(--primary-color));
  border-radius: 8px;
}

#families-filter::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--primary-color));
}
</style>
