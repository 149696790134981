import formatNumericString from '../format-numeric-string';

function format(nationalId: string) {
  const cleanNationalId = nationalId.replace(/\D/g, '');

  return formatNumericString(cleanNationalId);
}

const PERU_NATIONAL_ID_LENGTH = 8;
function validate(nationalId: string) {
  return format(nationalId).replaceAll('.', '').length === PERU_NATIONAL_ID_LENGTH;
}
export default { format, validate };
