import { ref, computed } from 'vue';

export default function useStepper(steps: unknown[]) {
  const currentStepIndex = ref(0);
  const currentStep = computed(() => steps[currentStepIndex.value]);
  const isLastStep = computed(() => currentStepIndex.value === steps.length - 1);

  function nextStep() {
    if (!isLastStep.value) {
      currentStepIndex.value++;
    }
  }

  function previousStep() {
    if (currentStepIndex.value > 0) {
      currentStepIndex.value--;
    }
  }

  return {
    currentStepIndex,
    currentStep,
    isLastStep,
    nextStep,
    previousStep,
  };
}
