<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import type { GoogleAutocompleteAddressData } from '@/types/google-autocomplete-response';
import type { Input } from '@/types/input';

interface Props {
  input: Input;
  translationPath: string;
  modelValue?: string | number | Date | GoogleAutocompleteAddressData;
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
});

const { t } = useI18n({});

const inputsMap = {
  'base': ['text', 'password', 'number'],
  'date': ['date'],
  'time': ['time'],
  'select': ['selector'],
  'googleAutocomplete': ['google_autocomplete'],
};

defineEmits<{(e: 'update:modelValue', value: string | number): void}>();

const inputType = computed(() => props.input.inputType);
const isBaseInput = inputsMap.base.includes(inputType.value);
const isSelectInput = inputsMap.select.includes(inputType.value);
const isGoogleAutocompleteInput = inputsMap.googleAutocomplete.includes(inputType.value);
const isDateInput = inputsMap.date.includes(inputType.value);
const isTimeInput = inputsMap.time.includes(inputType.value);

const placeholder = computed(() => (
  t(`${props.translationPath}.placeholders.${props.input.name}`,
    t(`${props.translationPath}.${props.input.name}`),
  )),
);

const inputLabel = computed(() => props.input.customName || t(`${props.translationPath}.${props.input.name}`));

</script>

<template>
  <base-input
    v-if="isBaseInput"
    :label="inputLabel"
    :name="`${input.name}-${input.kind}`"
    :placeholder="input.customName ?? placeholder"
    :type="input.inputType"
    :class="$attrs.class"
    :model-value="modelValue"
    :initial-value="input.initialValue ?? input.initialValue"
    :disabled="input.disabled"
    :optional="input.optional"
    @update:model-value="$emit('update:modelValue', $event)"
  />
  <base-date-input
    v-else-if="isDateInput"
    :label="inputLabel"
    :name="`${input.name}-${input.kind}`"
    :class="$attrs.class"
    :model-value="modelValue"
    :initial-value="input.initialValue ?? input.initialValue"
    :disabled="input.disabled"
    :optional="input.optional"
  />
  <base-select
    v-else-if="isSelectInput"
    :options="input.extraConfigurations.options"
    :label="inputLabel"
    :name="`${input.name}-${input.kind}`"
    :class="$attrs.class"
    :model-value="modelValue"
    :optional="input.optional"
    model-key="name"
    @update:model-value="$emit('update:modelValue', $event)"
  />
  <base-google-autocomplete-input
    v-else-if="isGoogleAutocompleteInput"
    :label="inputLabel"
    :name="`${input.name}-${input.kind}`"
    :model-value="modelValue"
    :optional="input.optional"
    @update:model-value="$emit('update:modelValue', $event)"
  />
  <base-time-input
    v-if="isTimeInput"
    :label="inputLabel"
    :name="`${input.name}-${input.kind}`"
    :placeholder="input.customName ?? placeholder"
    :class="$attrs.class"
    :model-value="modelValue"
    :initial-value="input.initialValue ?? input.initialValue"
    :disabled="input.disabled"
    :optional="input.optional"
    @update:model-value="$emit('update:modelValue', $event)"
  />
</template>
