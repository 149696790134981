<script  setup lang="ts">
import BaseModal from '@/components/base-modal.vue';

export type Props = {
  active: boolean
}
const props = defineProps<Props>();
defineEmits<{(e: 'close'): void}>();
</script>

<template>
  <base-modal
    :open="props.active"
    position-option="mixed"
  >
    <div class="flex flex-col items-center justify-center gap-6 md:max-w-[34.25rem]">
      <base-svg
        name="info-error"
        class="size-14"
      />
      <div class="flex flex-col gap-4 text-center">
        <p class="text-[2rem] font-semibold">
          {{ $t('serviceAssistance.failed.title') }}
        </p>
        <p>
          {{ $t('serviceAssistance.failed.firstText') }}
        </p>
        <p>
          {{ $t('serviceAssistance.failed.secondText') }}
        </p>
      </div>

      <base-button
        theme="primary-button"
        :label=" $t('serviceAssistance.failed.request')"
        rounded="md"
        class="w-full rounded-xl"
        @click="$emit('close')"
      />
    </div>
  </base-modal>
</template>
