<script setup lang="ts">
import { computed, ref } from 'vue';

import useSwipe from '@/composables/useSwipe';

interface Props {
  title?: string;
  description?: string;
  cardsCount: number;
  swipeButtonsTheme?: 'primary' | 'secondary' | 'dark';
}

const props = withDefaults(defineProps<Props>(),
  {
    title: undefined,
    description: undefined,
    swipeButtonsTheme: 'dark',
  },
);

defineEmits<{(e: 'click'): void}>();

const swipeableElement = ref<HTMLElement>();

const swipeButtonsClass = computed(() => {
  const SM_VISIBLE_CARDS = 1;
  const MD_VISIBLE_CARDS = 2;
  const LG_VISIBLE_CARDS = 3;
  if (props.cardsCount <= SM_VISIBLE_CARDS) return 'hidden';
  if (props.cardsCount <= MD_VISIBLE_CARDS) return 'md:hidden';
  if (props.cardsCount <= LG_VISIBLE_CARDS) return 'lg:hidden';

  return '';
});

const { swipeLeft, swipeRight } = useSwipe(swipeableElement);
</script>

<template>
  <div class="mx-auto xl:max-w-screen-xl 2xl:max-w-screen-2xl">
    <div
      v-if="title"
      class="mb-2 flex flex-row items-center justify-start"
    >
      <slot name="title-icon" />
      <h2
        class="text-3xl font-bold md:text-4xl"
      >
        {{ title }}
      </h2>
    </div>
    <h3
      v-if="description"
      class="mb-2 text-sm"
    >
      {{ description }}
    </h3>
    <slot name="info-button" />
    <slot name="filter-buttons" />
    <div
      ref="swipeableElement"
      class="flex flex-row space-x-6 overflow-x-auto overflow-y-hidden pb-6 pt-4 sm:pb-10 [&::-webkit-scrollbar]:hidden"
    >
      <slot name="cards" />
    </div>
    <div
      class="flex justify-end gap-x-2 pb-2"
      :class="swipeButtonsClass"
    >
      <base-icon-button
        type="button"
        icon-name="<"
        :variant="swipeButtonsTheme"
        rounded-size="full"
        class="shadow-md"
        size="sm"
        inverted
        @click="swipeLeft"
      />
      <base-icon-button
        type="button"
        icon-name=">"
        :variant="swipeButtonsTheme"
        rounded-size="full"
        class="shadow-md"
        size="sm"
        inverted
        @click="swipeRight"
      />
    </div>
  </div>
</template>
