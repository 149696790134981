<script setup lang="ts">
import { computed, inject } from 'vue';

import type { User } from '@/types/user';
import type { WebApp } from '@/types/web-app';

const currentUser = inject<User>('currentUser');
const webApp = inject<WebApp>('webApp');

const showMenu = computed(() => currentUser?.userApps && currentUser.userApps?.length > 1);
</script>
<template>
  <div
    v-if="showMenu"
    data-testid="apps-menu-drawer"
  >
    <hr class="border-gray-100">
    <h2 class="p-4 text-lg font-semibold">
      {{ $t('session.navigableApps') }}
    </h2>
    <a
      v-for="app in currentUser?.userApps"
      :key="app.mokServicesId"
      :data-testid="`app-${app.mokServicesId}`"
      :href="app.baseUrl"
      class="flex w-full items-center justify-between border border-slate-50 px-6 py-5 text-left font-medium hover:bg-gray-100 hover:text-primary"
      :class="{ 'pointer-events-none text-primary': app.mokServicesId === webApp?.mokServicesId }"
      :disabled="app.mokServicesId === webApp?.mokServicesId"
    >
      {{ app.name }}
      <span
        v-if="app.mokServicesId === webApp?.mokServicesId"
        class="text-xs text-gray-400"
      >
        {{ $t('session.currentApp') }}
      </span>
    </a>
  </div>
</template>
