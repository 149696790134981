<script setup lang="ts">
import { computed } from 'vue';

import type { ThirdFooter } from '@/types/third-footer';

import LegalDocumentsLink from '../../legal-documents-links.vue';

interface Props {
  thirdFooter: ThirdFooter;
}
const colorStyles = {
  primary: {
    bg: 'bg-primary',
    text: 'text-primary',
  },
  secondary: {
    bg: 'bg-secondary',
    text: 'text-secondary',
  },
};
const props = defineProps<Props>();
const bgColorVariant = computed(() => props.thirdFooter.bgColor || 'primary');
const bgColorClass = computed(() => colorStyles[bgColorVariant.value].bg);
const textColorClass = computed(() => colorStyles[bgColorVariant.value].text);
</script>

<template>
  <div
    class="flex w-full justify-center py-7 text-white "
    :class="bgColorClass"
  >
    <div class="flex grow flex-wrap items-start justify-between px-6 sm:px-16 md:max-w-screen-2xl">
      <div class="flex w-max grow flex-nowrap items-start justify-between gap-1">
        <img
          class="h-12 max-w-[100px] object-contain sm:h-20 sm:max-w-[200px]"
          :alt="$t('common.logo')"
          :src="thirdFooter.darkBgLogoUrl"
        >
        <div class="text-xs leading-6">
          <div class="hidden sm:block">
            <div>
              <p class="font-semibold">
                {{ $t('sections.footers.thirdFooter.contactPhoneText', { phoneNumber: thirdFooter.contactPhone }) }}
              </p>
            </div>
            <div>
              <legal-documents-link
                class="mt-6 flex flex-col gap-1 text-xs text-white sm:mt-1"
              />
            </div>
          </div>
          <div
            class="mt-1 flex gap-1 sm:mt-6 sm:gap-4"
            :class="textColorClass"
          >
            <base-icon-button
              v-if="thirdFooter.sponsorHomeLink"
              data-testid="sponsor-url-button"
              :href="thirdFooter.sponsorHomeLink"
              icon-name="home"
              target="_blank"
              :variant="bgColorVariant"
              :inverted="true"
              rounded-size="full"
            />
            <base-icon-button
              v-if="thirdFooter.contactEmail"
              data-testid="email-button"
              :href="`mailto:${thirdFooter.contactEmail}`"
              icon-name="mail"
              target="_blank"
              :variant="bgColorVariant"
              :inverted="true"
              rounded-size="full"
            />
            <base-icon-button
              v-if="thirdFooter.facebookLink"
              data-testid="facebook-button"
              :href="thirdFooter.facebookLink"
              icon-name="facebook"
              target="_blank"
              :variant="bgColorVariant"
              :inverted="true"
              rounded-size="full"
            />
            <base-icon-button
              v-if="thirdFooter.instagramLink"
              data-testid="instagram-button"
              :href="thirdFooter.instagramLink"
              icon-name="instagram"
              target="_blank"
              :variant="bgColorVariant"
              :inverted="true"
              rounded-size="full"
            />
          </div>
        </div>
      </div>
      <div class="mt-4 flex w-full flex-row items-start justify-between text-right text-2xs leading-3 sm:hidden">
        <p class="shrink-0">
          {{ $t('sections.footers.thirdFooter.contactPhoneText') }}
          <span class="font-semibold">{{ thirdFooter.contactPhone }}</span>
        </p>
        <div>
          <legal-documents-link
            :with-prefix="false"
            class="flex flex-col gap-1 text-2xs text-white"
          />
        </div>
      </div>
    </div>
  </div>
</template>
