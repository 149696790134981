import api from './index';

const BASE_PATH = '/api/internal/service_redemptions/';

export default {
  create(serviceId: number) {
    return api({
      method: 'post',
      url: BASE_PATH,
      data: { serviceId },
    });
  },
  update(id: number, usedAt: string) {
    return api({
      method: 'patch',
      url: `${BASE_PATH}${id}`,
      data: { usedAt },
    });
  },
};
