import { csrfToken } from '@rails/ujs';

import convertKeys from '@/utils/case-converter';

type FetchClientParams = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'get' | 'post' | 'put' | 'delete' | 'patch',
  path: string,
  params: Record<string, unknown>,
  keepalive?: boolean,
};

export default function ({
  method,
  path,
  params,
  keepalive,
}: FetchClientParams) {
  return fetch(path, {
    method,
    body: JSON.stringify(convertKeys(params, 'decamelize')),
    keepalive,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': csrfToken(),
    },
  });
}
