import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

import type { Category } from '@/types/category';
import type { WebApp } from '@/types/web-app';
import CouponsImage from '@assets/images/coupons.jpg';

export function useCouponFamily() {
  const webApp = inject<WebApp>('webApp');
  const hasCoupons = computed(() => webApp?.hasCoupons || false);
  const hasIncludedCoupons = computed(() => webApp?.hasIncludedCoupons || false);
  const showCouponFamily = computed(() => hasCoupons.value && hasIncludedCoupons.value);

  const { t } = useI18n();

  const couponFamily : Category = {
    id: 0,
    icon: 'coupon',
    name: t('coupons.title'),
    description: t('coupons.subtitle'),
    showPath: '/coupons/included',
    imageUrl: CouponsImage,
  };

  return showCouponFamily.value ? { couponFamily } : { couponFamily: undefined };
}

export default function useFamiliesWithIncludedCoupons(families: Category[]) {
  const { couponFamily } = useCouponFamily();
  const hasFamiliesOrCoupons = computed(() => families.length + (couponFamily ? 1 : 0) > 0);
  const familiesWithCoupons = computed(() => (
    couponFamily ? [...families, couponFamily] : families
  ));

  return {
    families: familiesWithCoupons.value,
    hasFamilies: hasFamiliesOrCoupons.value,
  };
}
