type RedemptionKinds = 'singleUse' | 'unlimitedAccess';

function classesByRedemptionKind(redemptionKind: RedemptionKinds) {
  const classes = {
    unlimitedAccess: 'bg-primary/30 text-primary',
    singleUse: 'bg-secondary/30 text-secondary',
  };

  return redemptionKind ? classes[redemptionKind] : '';
}

function iconSrcByRedemptionKind(redemptionKind: RedemptionKinds) {
  if (!redemptionKind) return undefined;

  switch (redemptionKind) {
  case 'unlimitedAccess':
    return 'bolt';
  case 'singleUse':
    return 'circled-one';
  default:
    return undefined;
  }
}

export { classesByRedemptionKind, iconSrcByRedemptionKind };
