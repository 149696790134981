<script setup lang="ts">
import type { LoyaltyInfoModalsState } from '@/types/loyalty-info-modals-state';

import LoyaltyInfoModal from './loyalty-info-modal.vue';
import LoyaltyRedemptionInfoModal from './loyalty-redemption-info-modal.vue';
import LoyaltyRewardInfoModal from './loyalty-reward-info-modal.vue';

interface Props {
  loyaltyInfoModalsState: LoyaltyInfoModalsState;
}

defineProps<Props>();
const emits = defineEmits<{(e: 'close'): void}>();

function emitClose() {
  emits('close');
}
</script>
<template>
  <loyalty-info-modal
    :open="!!loyaltyInfoModalsState['loyalty-info-modal']"
    @close="emitClose"
  />
  <loyalty-reward-info-modal
    :open="!!loyaltyInfoModalsState['loyalty-reward-info-modal']"
    @close="emitClose"
  />
  <loyalty-redemption-info-modal
    :open="!!loyaltyInfoModalsState['loyalty-redemption-info-modal']"
    @close="emitClose"
  />
</template>
