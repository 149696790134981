<script setup lang="ts">
import { csrfToken } from '@rails/ujs';
import { inject, computed } from 'vue';
import { useMq } from 'vue3-mq';

import type { FourthNavbar } from '@/types/fourth-navbar';
import type { User } from '@/types/user';

import FriendlyNavbarMenu from './friendly-navbar-menu.vue';
import NavbarDropdown from './navbar-dropdown.vue';

interface Props {
  fourthNavbar: FourthNavbar;
}
const mq = useMq();

const props = defineProps<Props>();

const currentUser = inject<User | undefined>('currentUser');

const primary = computed(() => (
  props.fourthNavbar.backgroundColor === 'primary' || !props.fourthNavbar.backgroundColor
));
const secondary = computed(() => (props.fourthNavbar.backgroundColor === 'secondary'));

</script>

<template>
  <div
    class="flex w-full justify-center"
    :class="{ 'bg-primary': primary, 'bg-secondary': secondary}"
  >
    <div class="flex w-full items-center justify-between px-6 py-4 sm:px-16 md:max-w-screen-2xl">
      <div
        v-if="mq.xs"
        class="flex items-center"
      >
        <a href="/">
          <img
            v-if="fourthNavbar.darkBgLogoUrl"
            class="h-11 sm:h-16"
            alt="logo"
            :src="fourthNavbar.darkMobileLogoUrl"
          >
        </a>
      </div>
      <div
        v-else
        class="flex items-center"
      >
        <a href="/">
          <img
            v-if="fourthNavbar.darkBgLogoUrl"
            class="h-11 sm:h-16"
            alt="logo"
            :src="fourthNavbar.darkBgLogoUrl"
          >
        </a>
      </div>
      <template v-if="fourthNavbar.authUrl">
        <friendly-navbar-menu
          v-if="currentUser"
          :current-user="currentUser"
          :logo-url="fourthNavbar.logoUrl"
          :edit-user-path="fourthNavbar.editUserPath"
          :service-redemptions-path="fourthNavbar.serviceRedemptionsPath"
          class="text-white"
        />
        <template v-else>
          <navbar-dropdown
            class="text-white sm:hidden"
            :auth-url="fourthNavbar.authUrl"
          />
          <form
            :action="fourthNavbar.authUrl"
            method="post"
            class="ml-auto hidden items-center sm:flex"
          >
            <base-link
              data-testid="register-button"
              theme="white"
              type="submit"
              class="text-sm font-semibold"
              :label="$t('sections.navbars.shared.register')"
            />
            <input
              type="hidden"
              name="authenticity_token"
              :value="csrfToken()"
              tabindex="-1"
            >
            <input
              type="hidden"
              name="prompt"
              value="create"
              tabindex="-1"
            >
          </form>
          <form
            :action="fourthNavbar.authUrl"
            method="post"
            class="ml-6 hidden items-center sm:flex"
          >
            <base-button
              data-testid="login-button"
              theme="white"
              type="submit"
            >
              <span
                class="text-sm font-semibold"
                :class="{ 'text-primary': primary, 'text-secondary': secondary }"
              >
                {{ $t('sections.navbars.shared.login') }}
              </span>
            </base-button>
            <input
              type="hidden"
              name="authenticity_token"
              :value="csrfToken()"
              tabindex="-1"
            >
          </form>
        </template>
      </template>
    </div>
  </div>
</template>
