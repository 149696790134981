// https://logaretm.com/blog/type-safe-provide-inject/
import { inject } from 'vue';

export default function injectStrict<T>(key: string, fallback?: T) {
  const resolved = inject(key, fallback);
  if (!resolved) {
    throw new Error(`Could not resolve ${key}`);
  }

  return resolved;
}
