<script setup lang="ts">
import { ref, computed } from 'vue';

import type { QuestionSectionField } from '@/types/question-section-field';

interface Props {
  question: QuestionSectionField;
}

defineProps<Props>();

const isOpen = ref(false);
const isClosed = computed(() => !isOpen.value);

const iconName = computed(() => (isOpen.value ? 'chevron-up' : 'chevron-down'));

function handleClick() {
  isOpen.value = !isOpen.value;
}

</script>

<template>
  <div class="mb-5 gap-x-4 rounded-2xl bg-white p-6 shadow-md sm:flex sm:justify-between sm:gap-x-20 sm:px-20 sm:py-12">
    <div class="gap-y-4 sm:flex sm:flex-col">
      <div class="flex w-full justify-between">
        <p class="text-sm font-medium text-gray-900 sm:text-xl">
          {{ question.title }}
        </p>
        <base-icon-button
          type="button"
          :icon-name="iconName"
          variant="primary"
          rounded-size="full"
          class="stroke-2 shadow-md sm:hidden"
          size="sm"
          :inverted="isClosed"
          @click="handleClick"
        />
      </div>
      <p
        v-if="isOpen"
        class="text-xs leading-loose text-gray-700 sm:text-lg sm:leading-normal"
      >
        {{ question.content }}
      </p>
    </div>
    <base-icon-button
      type="button"
      :icon-name="iconName"
      variant="primary"
      rounded-size="full"
      class="hidden stroke-2 shadow-md sm:block"
      size="lg"
      :inverted="isClosed"
      @click="handleClick"
    />
  </div>
</template>
