<script setup lang="ts">
import { onMounted } from 'vue';

const SLEEP_TIME = 2000;

function sendGoBackMessage() {
  window.parent?.postMessage('iframeCallback', '*');
}

onMounted(() => {
  setTimeout(() => {
    sendGoBackMessage();
  }, SLEEP_TIME);
});

</script>
<template>
  <div
    data-testid="loading"
    class="flex h-max flex-col items-center justify-center text-center"
  >
    <base-svg
      name="loading"
      class="mt-24 h-36 animate-spin stroke-current text-primary"
    />
    <h1 class="text-2xl font-bold md:mb-2">
      {{ $t('iframe.callback.title') }}
    </h1>
    <p class="text-center text-xl">
      {{ $t('iframe.callback.description') }}
    </p>
  </div>
</template>
