<template>
  <canvas
    ref="canvas"
    class="absolute inset-0 size-full"
    :height="initialCanvasSize"
    :width="initialCanvasSize"
  />
</template>

<script setup lang="ts">
import { decode } from 'blurhash';
import { ref, onMounted } from 'vue';

const props = defineProps<{
  blurhash: string;
}>();

const initialCanvasSize = 32;
const canvas = ref<HTMLCanvasElement | null>(null);

onMounted(() => {
  const pixels = decode(props.blurhash, initialCanvasSize, initialCanvasSize);
  const imageData = new ImageData(pixels, initialCanvasSize, initialCanvasSize);
  const context = canvas.value?.getContext('2d');
  context?.putImageData(imageData, 0, 0);
});
</script>
