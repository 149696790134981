<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import useFamilyServicesFilters from '@/composables/useFamilyServicesFilters';
import type { FourthMainContent } from '@/types/fourth-main-content';
import familyIconSrc from '@/utils/family-icon-src';

import FourthMainContentServiceCard from './fourth-main-content-service-card.vue';
import PaginatedServices from './paginated-services.vue';

interface Props {
  fourthMainContent: FourthMainContent;
}

const props = defineProps<Props>();

const { t } = useI18n();

const families = computed(() => props.fourthMainContent.families);
const {
  couponFamily,
  familiesWithAllServices,
  hasFamiliesOrCoupons,
  selectedFamilyIndex,
  selectFamily,
  services,
} = useFamilyServicesFilters(families);

const showFilters = computed(() => !props.fourthMainContent.hideFilters);

const iconColor = computed(() => props.fourthMainContent.iconColor || 'primary');
const selectedFamilyColorClass = computed(() => {
  const colorClassByIconColor = {
    primary: 'text-primary border-primary',
    secondary: 'text-secondary border-secondary',
  };

  return colorClassByIconColor[iconColor.value];
});
</script>

<template>
  <div
    v-if="hasFamiliesOrCoupons"
    id="main-content"
    class="mx-auto flex w-full flex-col px-6 pb-7 pt-14 sm:px-16 sm:py-24 md:max-w-screen-2xl"
  >
    <h2 class="mb-12 text-center text-xl font-medium sm:px-0 sm:text-5xl">
      {{ props.fourthMainContent.title ?? $t('sections.mainContents.shared.title') }}
    </h2>
    <div
      v-if="showFilters"
      class="mx-auto mb-8 grid w-full max-w-full grid-cols-2 justify-center gap-2 sm:mb-24 sm:w-full sm:grid-cols-3 md:flex md:flex-wrap md:gap-x-9"
    >
      <!-- eslint-disable tailwindcss/no-custom-classname -->
      <button
        v-for="(family, index) in familiesWithAllServices"
        :key="family.id"
        class="flex items-center justify-center gap-2.5 rounded-lg border px-2 py-2.5 sm:gap-4 sm:p-4"
        :class="{
          'border-gray-400 text-gray-600': selectedFamilyIndex !== index,
          [selectedFamilyColorClass]: selectedFamilyIndex === index,
        }"
        :data-testid="`${family.name}-family-button`"
        @click="selectFamily(index)"
      >
        <base-svg
          v-bind="familyIconSrc(family)"
          class="size-5 fill-current sm:size-6"
        />
        <span class="text-xs sm:text-base">
          {{ family.name }}
        </span>
      </button>
      <a
        v-if="couponFamily"
        class="flex items-center justify-center gap-2.5 rounded-lg border border-gray-400 px-2 py-2.5 text-gray-600 sm:gap-4 sm:p-4"
        :data-testid="`${couponFamily.name}-family-button`"
        :href="couponFamily.showPath"
      >
        <base-svg
          v-bind="familyIconSrc(couponFamily)"
          class="size-5 fill-current sm:size-6"
        />
        <span class="text-xs sm:text-base">
          {{ t('coupons.titleShort') }}
        </span>
      </a>
      <!-- eslint-enable tailwindcss/no-custom-classname -->
    </div>
    <paginated-services
      v-slot="{ currentPageServices }"
      :services="services"
    >
      <fourth-main-content-service-card
        v-for="service in currentPageServices"
        :key="service.id"
        :service="service"
      />
    </paginated-services>
  </div>
</template>
