<script setup lang="ts">
import { computed, useAttrs } from 'vue';

const labelSizeClass = {
  xs: 'text-xs',
  sm: 'text-sm',
};

const labelWeightClass = {
  bold: 'font-bold',
  normal: 'font-normal',
};

interface Props {
  modelValue?: unknown[],
  value: unknown,
  name: string,
  label: string
  size?: keyof typeof labelSizeClass;
  weight?: keyof typeof labelWeightClass;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  size: 'sm',
  weight: 'normal',
});

const attrs = useAttrs();
const inputAttrs = Object.fromEntries(Object.entries(attrs).filter((entry) => entry[0] !== 'class'));

const emit = defineEmits<{(e: 'update:modelValue', value: unknown): void}>();

const computedValue = computed({
  get: () => (props.modelValue),
  set(value) {
    emit('update:modelValue', value);
  },
});
</script>

<template>
  <div
    class="my-2 flex items-center gap-4"
    :class="$attrs.class"
  >
    <input
      v-model="computedValue"
      class="size-4"
      type="checkbox"
      :name="name"
      :value="value"
      v-bind="inputAttrs"
    >
    <label
      :for="name"
      class="text-gray-700"
      :class="[labelSizeClass[size], labelWeightClass[weight]]"
    >
      {{ label }}
    </label>
  </div>
</template>
