import { rutValidate, rutFormat } from 'rut-helpers';

function format(nationalId: string) {
  return rutFormat(nationalId);
}

function validate(nationalId: string) {
  return rutValidate(nationalId);
}

export default { format, validate };
