<script setup lang="ts">
import { useField } from 'vee-validate';

import useAttrsWithoutClass from '@/composables/useAttrsWithoutClass';

interface Props {
  modelValue?: string;
  label: string;
  name: string;
  initialValue?: string;
  optional?: boolean;
  disabled?: boolean;
  disabledDescription?: string;
  prefix: string;
  flag?: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  initialValue: undefined,
  optional: false,
  disabled: false,
  disabledDescription: undefined,
  flag: undefined,
});

const attrsWithoutClass = useAttrsWithoutClass();

const emit = defineEmits<{(e: 'update:modelValue', value: string): void}>();

const {
  value: inputValue,
  errorMessage,
  meta,
  handleBlur,
  handleChange,
} = useField(props.name, undefined, {
  initialValue: props.initialValue || props.modelValue,
});

function phoneFormatFunction(value: string) {
  return value.replace(/[^0-9]/g, '');
}

function handleInputChange(e: Event) {
  const input = e.target as HTMLInputElement;
  input.value = phoneFormatFunction(input.value);
  handleChange(e);
  emit('update:modelValue', input.value);
}

</script>
<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<template>
  <div
    class="flex flex-col space-y-1"
    :class="$attrs.class"
  >
    <label
      :for="name"
      class="text-sm font-normal text-gray-700"
    >
      {{ label }}
      <span
        v-if="optional"
        class="text-xs opacity-40"
      >
        ({{ $t('common.optional') }})
      </span>
      <span
        v-else
        class="text-xs text-red-500"
      >
        *
      </span>
    </label>
    <div
      class="flex h-10 w-full items-center gap-x-2 rounded border px-2 text-gray-700 placeholder:text-sm"
      :class="{
        'border-error-600': !!errorMessage && meta.touched,
        'border-gray-300': !errorMessage || !meta.touched,
        'bg-gray-100': disabled,
      }"
    >
      <span v-if="flag">
        {{ flag }}
      </span>
      <span>
        {{ prefix }}
      </span>
      <input
        :id="name"
        :name="name"
        :value="inputValue"
        class="grow focus:outline-none"
        type="tel"
        :disabled="disabled"
        v-bind="attrsWithoutClass"
        @input="handleInputChange($event)"
        @blur="handleBlur"
      >
      <base-tooltip
        v-if="disabled && disabledDescription"
        :text="disabledDescription"
      >
        <div class="flex h-full items-center">
          <base-svg
            name="info-circle"
            class="size-4 stroke-primary"
          />
        </div>
      </base-tooltip>
    </div>
    <p
      v-show="!!errorMessage && meta.touched"
      class="text-left text-xs text-error-600"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
