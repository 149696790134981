<script setup lang="ts">
import { computed, useAttrs } from 'vue';

interface Props {
  iconName: string;
  variant: 'primary' | 'secondary' | 'dark' | 'white';
  inverted?: boolean;
  roundedSize?: 'sm' | 'md' | 'lg' | 'full';
  size?: 'sm' | 'md' | 'lg';
}
const props = withDefaults(defineProps<Props>(), {
  variant: 'primary',
  inverted: false,
  roundedSize: 'lg',
  size: 'md',
});

const attrs = useAttrs();
const currentTag = computed(() => (attrs.href ? 'a' : 'button'));

const roundedVariants = {
  sm: 'rounded-sm',
  md: 'rounded-md',
  lg: 'rounded-lg',
  full: 'rounded-full',
};
const colorVariants = {
  primary: {
    default: 'bg-primary text-white',
    inverted: 'bg-white text-primary',
  },
  secondary: {
    default: 'bg-secondary text-white',
    inverted: 'bg-white text-secondary',
  },
  dark: {
    default: 'bg-gray-900 text-white hover:bg-white hover:text-gray-900',
    inverted: 'bg-white text-gray-900',
  },
  white: {
    default: 'text-white bg-transparent',
    inverted: 'bg-white text-gray-900',
  },
};
const sizeVariants = {
  sm: 'h-7 sm:h-9 p-2 sm:p-3',
  md: 'h-8 sm:h-11 p-2 sm:p-3',
  lg: 'h-9 sm:h-12 p-3 sm:p-4',
};

const roundedStyle = computed(() => roundedVariants[props.roundedSize]);
const sizeStyle = computed(() => sizeVariants[props.size]);

const iconStyle = computed(() => {
  const variant = colorVariants[props.variant];
  const baseStyle = props.inverted ? variant.inverted : variant.default;

  return baseStyle;
});
</script>
<template>
  <component
    :is="currentTag"
    class="flex aspect-square items-center justify-center"
    :class="[iconStyle, roundedStyle, sizeStyle]"
  >
    <base-svg
      data-testid="base-icon-button-svg"
      :name="`${iconName}`"
      class="size-full stroke-current"
    />
  </component>
</template>
