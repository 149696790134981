<script setup lang="ts">
import { computed } from 'vue';

type Color = 'success' | 'error';
type Theme = 'light' | 'medium' | 'dark';

type ThemeStyles = {
  [key in Theme]: string;
}

type ColorStyles = {
  [key in Color]: string;
}

type ColorThemeStyles = {
  [key in Color]: ThemeStyles;
}

type Props = {
  /** The text to display in the badge. It must be a key in the i18n file. */
  label: string;
  color: Color;
  theme: Theme;
}

const props = defineProps<Props>();

const bgStyles: ColorThemeStyles = {
  success: {
    light: 'bg-success-50',
    medium: 'bg-success-100',
    dark: 'bg-success-50',
  },
  error: {
    light: 'bg-error-50',
    medium: 'bg-error-100',
    dark: 'bg-error-50',
  },
};

const bgIconStyles: ColorThemeStyles = {
  success: {
    light: 'bg-white',
    medium: 'bg-success-50',
    dark: 'bg-success-600',
  },
  error: {
    light: 'bg-white',
    medium: 'bg-error-25',
    dark: 'bg-error-600',
  },
};

const icons: ColorStyles = {
  success: 'check',
  error: 'alert-circle',
};

const iconStyles: ColorThemeStyles = {
  success: {
    light: 'stroke-success-600',
    medium: 'stroke-success-600',
    dark: 'stroke-white',
  },
  error: {
    light: 'stroke-error-600',
    medium: 'stroke-error-600',
    dark: 'stroke-error-25',
  },
};

const textStyles: ColorStyles = {
  success: 'text-success-700',
  error: 'text-error-700',
};

const bgStyle = computed(() => bgStyles[props.color][props.theme]);
const bgIconStyle = computed(() => bgIconStyles[props.color][props.theme]);
const iconStyle = computed(() => iconStyles[props.color][props.theme]);
const textStyle = computed(() => textStyles[props.color]);
const iconSrc = computed(() => icons[props.color]);

</script>

<template>
  <div
    class="flex items-center justify-center space-x-2 rounded-full p-1  pr-2.5"
    :class="bgStyle"
  >
    <div
      data-testid="base-badge-icon-container"
      class="flex aspect-square h-4 items-center justify-center rounded-full"
      :class="bgIconStyle"
    >
      <base-svg
        data-testid="base-badge-icon"
        class="h-3"
        :class="iconStyle"
        :name="iconSrc"
      />
    </div>
    <p
      class="text-xs font-medium md:text-sm"
      :class="textStyle"
    >
      {{ $t(label) }}
    </p>
  </div>
</template>
