<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useMq } from 'vue3-mq';

import type { Service, ServiceWithFamilyInfo } from '@/types/service';

import BasePaginator from '../../base-paginator.vue';

interface Props {
  services: (Service | ServiceWithFamilyInfo)[];
}

const props = defineProps<Props>();

const mq = useMq();
const isLgScreen = computed(() => mq.lgPlus);
const maxRows = 3;
const largeScreenColumns = 3;
const smallScreenColumns = 2;
const maxCards = computed(() => maxRows * (isLgScreen.value ? largeScreenColumns : smallScreenColumns));

const totalPages = computed(() => Math.ceil((props.services.length || 0) / maxCards.value));
const currentPage = ref(1);
const currentPageServices = computed(() => {
  const start = (currentPage.value - 1) * maxCards.value;
  const end = start + maxCards.value;

  return props.services.slice(start, end) || [];
});

watch(() => isLgScreen.value, () => {
  currentPage.value = Math.min(currentPage.value, totalPages.value);
});

const servicesContainer = ref<HTMLElement | undefined>();
watch(() => currentPage.value, () => {
  servicesContainer.value?.scrollIntoView({ behavior: 'instant' });
});

watch(() => props.services, () => {
  currentPage.value = 1;
});
</script>

<template>
  <div
    ref="servicesContainer"
    class="grid w-full grid-cols-2 gap-2 lg:grid-cols-3 lg:gap-x-16 lg:gap-y-9"
  >
    <slot :current-page-services="currentPageServices" />
  </div>
  <base-paginator
    class="mt-12 self-center"
    :total-pages="totalPages"
    :current-page="currentPage"
    @page-changed="page => currentPage = page"
  />
</template>
