<script setup lang="ts">
import { ref, computed } from 'vue';

import loyaltyInfoModalsContent from '@/values/loyalty-info-modals-content.json';

import LoyaltyInfoMobileContent from './loyalty-info-mobile-content.vue';

type ContentKeys = keyof typeof loyaltyInfoModalsContent;

const selectedContentKey = ref<ContentKeys | undefined >(undefined);

function selectContent(contentKey: ContentKeys) {
  selectedContentKey.value = contentKey;
}

function unselectContent() {
  selectedContentKey.value = undefined;
}

const selectedContent = computed(() => (
  selectedContentKey.value ? loyaltyInfoModalsContent[selectedContentKey.value] : undefined
));

const noContentSelected = computed(() => !selectedContent.value);

</script>
<template>
  <div class="md:hidden">
    <div
      v-if="noContentSelected"
      class="mx-auto flex w-full flex-col items-center text-center"
    >
      <div class="flex size-28 items-center justify-center rounded-full bg-secondary/20">
        <base-svg
          name="trophy-cup"
          class="w-20 fill-secondary"
        />
      </div>
      <p class="mt-4 text-xl font-bold">
        {{ $t('loyalty.modals.info.title') }}
      </p>
      <p class="mt-4 text-base text-gray-800">
        {{ $t('loyalty.modals.info.description') }}
      </p>
      <button
        v-for="(content, contentKey) in loyaltyInfoModalsContent"
        :key="`mobile-loyalty-info-${contentKey}`"
        :data-testid="`${contentKey}-button`"
        class="mt-4 flex w-full flex-row items-center justify-between rounded border border-slate-100 py-3 pl-6 pr-8"
        @click="selectContent(contentKey)"
      >
        <p class="text-base text-gray-500">
          {{ $t(content.title) }}
        </p>
        <base-svg
          name="back"
          class="size-4 rotate-180 fill-gray-500"
        />
      </button>
    </div>
    <div
      v-else
      class="mx-auto flex w-full flex-col items-center text-center md:hidden"
    >
      <button
        class="absolute left-4 top-7"
        @click="unselectContent"
      >
        <base-svg
          name="back"
          class="size-4 fill-current"
        />
      </button>
      <loyalty-info-mobile-content
        :content-key="(selectedContentKey as ContentKeys)"
      />
    </div>
  </div>
</template>
