<script setup lang="ts">
import { csrfToken } from '@rails/ujs';
import { inject } from 'vue';
import { useMq } from 'vue3-mq';

import type { ThirdNavbar } from '@/types/third-navbar';
import type { User } from '@/types/user';

import FriendlyNavbarMenu from './friendly-navbar-menu.vue';
import NavbarDropdown from './navbar-dropdown.vue';

interface Props {
  thirdNavbar: ThirdNavbar;
}

defineProps<Props>();

const currentUser = inject<User | undefined>('currentUser');

const mq = useMq();

</script>

<template>
  <div class="mx-auto flex w-full justify-between bg-white px-6 py-4 sm:px-16 md:max-w-screen-2xl">
    <div
      v-if="mq.xs"
      class="flex items-center"
    >
      <a href="/">
        <img
          v-if="thirdNavbar.logoUrl"
          class="h-11 sm:h-16"
          alt="logo"
          :src="thirdNavbar.mobileLogoUrl"
        >
      </a>
    </div>
    <div
      v-else
      class="flex items-center"
    >
      <a href="/">
        <img
          v-if="thirdNavbar.logoUrl"
          class="h-11 sm:h-16"
          alt="logo"
          :src="thirdNavbar.logoUrl"
        >
      </a>
    </div>
    <template v-if="thirdNavbar.authUrl">
      <friendly-navbar-menu
        v-if="currentUser"
        :current-user="currentUser"
        :logo-url="thirdNavbar.logoUrl"
        :edit-user-path="thirdNavbar.editUserPath"
        :service-redemptions-path="thirdNavbar.serviceRedemptionsPath"
        class="ml-auto"
      />
      <template v-else>
        <navbar-dropdown
          class="text-primary sm:hidden"
          :auth-url="thirdNavbar.authUrl"
        />
        <form
          :action="thirdNavbar.authUrl"
          method="post"
          class="ml-auto hidden items-center sm:flex"
        >
          <base-link
            data-testid="register-button"
            theme="primary"
            type="submit"
            class="text-sm font-semibold"
            :label="$t('sections.navbars.shared.register')"
          />
          <input
            type="hidden"
            name="authenticity_token"
            :value="csrfToken()"
            tabindex="-1"
          >
          <input
            type="hidden"
            name="prompt"
            value="create"
            tabindex="-1"
          >
        </form>
        <form
          :action="thirdNavbar.authUrl"
          method="post"
          class="ml-6 hidden items-center sm:flex"
        >
          <base-button
            data-testid="login-button"
            theme="primary-button-outline"
            type="submit"
          >
            <span class="text-sm font-semibold">
              {{ $t('sections.navbars.shared.login') }}
            </span>
          </base-button>
          <input
            type="hidden"
            name="authenticity_token"
            :value="csrfToken()"
            tabindex="-1"
          >
        </form>
      </template>
    </template>
  </div>
</template>
