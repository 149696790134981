<script setup lang="ts">
import { ref } from 'vue';

import type { User } from '@/types/user';

import FriendlyNavbarProfile from './friendly-navbar-profile.vue';
import NavbarMenuDrawer from './navbar-menu-drawer.vue';

interface Props {
  currentUser: User;
  logoUrl?: string;
  serviceRedemptionsPath: string;
  editUserPath?: string;
}

withDefaults(defineProps<Props>(), {
  logoUrl: undefined,
  editUserPath: undefined,
});

const menuIsOpen = ref(false);

function toggleMenu() {
  menuIsOpen.value = !menuIsOpen.value;
}

function closeMenu() {
  menuIsOpen.value = false;
}
</script>

<template>
  <navbar-menu-drawer
    v-if="menuIsOpen"
    :logo-url="logoUrl"
    :edit-user-path="editUserPath"
    :service-redemptions-path="serviceRedemptionsPath"
    @close="closeMenu"
  >
    <template #profile>
      <friendly-navbar-profile
        :current-user="currentUser"
      />
    </template>
  </navbar-menu-drawer>
  <div
    v-else
    class="flex cursor-pointer items-center"
  >
    <button
      class="flex w-full cursor-text items-center gap-x-2"
      @click="toggleMenu()"
    >
      <friendly-navbar-profile
        :current-user="currentUser"
      />

      <base-svg
        name="chevron-right"
        class="size-4 stroke-current"
      />
    </button>
  </div>
</template>
