<script setup lang="ts">
import { computed } from 'vue';

import useSiteVariables from '@/composables/useSiteVariables';

const { isTermsAndConditionsActive, isPrivacyPolicyActive } = useSiteVariables();
interface Props {
  withPrefix?: boolean;
  hideTermsAndConditions?: boolean;
  hidePrivacyPolicy?: boolean;
}

const props = withDefaults(
  defineProps<Props>(),
  {
    withPrefix: true,
    hideTermsAndConditions: false,
    hidePrivacyPolicy: false,
  },
);

const showTermsAndConditions = computed(() => isTermsAndConditionsActive && !props.hideTermsAndConditions);
const showPrivacyPolicy = computed(() => isPrivacyPolicyActive && !props.hidePrivacyPolicy);

const privacyPolicyPrefix = computed(() => {
  if (showTermsAndConditions.value && showPrivacyPolicy.value) {
    return 'common.privacyPolicy.prefixMultiple';
  }

  return 'common.privacyPolicy.prefix';
});

</script>

<template>
  <div v-if="withPrefix">
    <i18n-t
      v-if="showTermsAndConditions"
      keypath="common.termsAndConditions.prefix"
      tag="span"
      for="common.termsAndConditions.content"
    >
      <a
        href="/terms-and-conditions"
        class="underline"
      >
        {{ $t('common.termsAndConditions.content') }}
      </a>
    </i18n-t>

    <i18n-t
      v-if="showPrivacyPolicy"
      :keypath="privacyPolicyPrefix"
      tag="span"
      for="common.privacyPolicy.content"
    >
      <a
        href="/privacy-policy"
        class="underline"
      >
        {{ $t('common.privacyPolicy.content') }}
      </a>
    </i18n-t>
  </div>
  <div v-else>
    <a
      v-if="showTermsAndConditions"
      href="/terms-and-conditions"
      class="underline"
    >
      {{ $t('common.termsAndConditions.content') }}
    </a>
    <a
      v-if="showPrivacyPolicy"
      href="/privacy-policy"
      class="underline"
    >
      {{ $t('common.privacyPolicy.content') }}
    </a>
  </div>
</template>
