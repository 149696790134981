const FORMAT_NUMBER_GROUP_LENGTH = 3;

function formatNumericString(numericString: string) {
  return numericString
    .split('')
    .reverse()
    .reduce((acc, digit, index) => digit + (index && index % FORMAT_NUMBER_GROUP_LENGTH === 0 ? '.' : '') + acc, '');
}

export default formatNumericString;
