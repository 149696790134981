<script setup lang="ts">
import { computed, inject, ref } from 'vue';
import { useQuery } from 'vue-query';

import cuponstarCategoryApi from '@/api/cuponstar-category';
import useSiteVariables from '@/composables/useSiteVariables';
import type { CuponstarCategory } from '@/types/cuponstar-category';

import CouponsViewCategorySection from './coupons-view-category-section.vue';
import CouponsViewHero from './coupons-view-hero.vue';
import CouponsViewRedeemModal from './coupons-view-redeem-modal.vue';

interface Props {
  cuponstarCategoriesProp: CuponstarCategory[];
  mode: 'redeemable' | 'included'
}

const props = defineProps<Props>();

const modalOpen = ref(false);

const { data: cuponstarCategoriesOrUndefined } = useQuery(
  'cuponstar-categories',
  cuponstarCategoryApi[props.mode],
  { initialData: props.cuponstarCategoriesProp },
);
const cuponstarCategories = computed(() => cuponstarCategoriesOrUndefined.value as CuponstarCategory[]);

const selectedCategoryIndex = ref<number>();
const selectedCategory = computed(
  () => (
    selectedCategoryIndex.value === undefined ? undefined : cuponstarCategories.value[selectedCategoryIndex.value]
  ),
);
const selectedCategoryName = computed(() => selectedCategory.value?.name);
const selectedCouponIndex = ref<number>();
const selectedCoupon = computed(
  () => (
    selectedCouponIndex.value === undefined ?
      undefined :
      selectedCategory.value?.[`${props.mode}Coupons`]?.[selectedCouponIndex.value]
  ),
);

type ToggleCouponLoginRedirectType = () => void;

const toggleCouponLoginRedirect = inject('toggleCouponLoginRedirect') as ToggleCouponLoginRedirectType;

const { isLoginRequired } = useSiteVariables();

function openModal(couponIndex: number, categoryIndex: number) {
  selectedCouponIndex.value = couponIndex;
  selectedCategoryIndex.value = categoryIndex;
  modalOpen.value = true;
}

function closeModal() {
  selectedCouponIndex.value = undefined;
  selectedCategoryIndex.value = undefined;
  modalOpen.value = false;
}

function onCouponRedeemClick(couponIndex: number, categoryIndex: number) {
  if (isLoginRequired) {
    toggleCouponLoginRedirect();
  } else {
    openModal(couponIndex, categoryIndex);
  }
}
</script>

<template>
  <coupons-view-hero />
  <coupons-view-category-section
    v-for="(cuponstarCategory, categoryIndex) in cuponstarCategories"
    :key="cuponstarCategory.id"
    :cuponstar-category="cuponstarCategory"
    :mode="mode"
    @call-to-action-clicked="(couponIndex) => onCouponRedeemClick(couponIndex, categoryIndex)"
  />
  <coupons-view-redeem-modal
    v-if="!!selectedCoupon && !!selectedCategoryName"
    :coupon="selectedCoupon"
    :category-name="selectedCategoryName"
    :open="modalOpen"
    @close="closeModal"
  />
</template>
