import formatNumericString from './format-numeric-string';

function formatNumber(number?: number) {
  if (number !== undefined) {
    const numericString = number.toString();

    return formatNumericString(numericString);
  }

  return number;
}

export default formatNumber;
