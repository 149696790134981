<script setup lang="ts">
import { computed } from 'vue';

import useSiteVariables from '@/composables/useSiteVariables';

export interface RedeMaisSaudeAppointment {
  status: string;
  beneficiaryName: string;
  nationalId: string;
  confirmedDateTime: string;
  specialty: string;
  roomUrl: string;
  providerName: string;
  baseCost: number;
  discountedCost: number;
}

interface Props {
  redeMaisSaudeAppointment: RedeMaisSaudeAppointment;
}
const props = defineProps<Props>();

const { locale: siteLocale } = useSiteVariables();

const ONE_HUNDRED = 100;
const discountRate = computed(() => (
  (
    props.redeMaisSaudeAppointment.baseCost -
    props.redeMaisSaudeAppointment.discountedCost
  ) / props.redeMaisSaudeAppointment.baseCost) * ONE_HUNDRED,
);

function toNormalCase(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

function isValidDate(date: Date) {
  return !isNaN(date.getTime());
}

const confirmedDate = computed(() => {
  const date = new Date(props.redeMaisSaudeAppointment.confirmedDateTime);

  return date.toLocaleDateString(siteLocale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
});

const confirmedTime = computed(() => {
  const date = new Date(props.redeMaisSaudeAppointment.confirmedDateTime);

  return date.toLocaleTimeString(siteLocale, {
    hour: '2-digit',
    minute: '2-digit',
  });
});

const REDUNDANT_STATUS_TEXTS = ['DO BENEFICIÁRIO', 'COM O BENEFICIÁRIO'];
const statusWithoutRedundantText = computed(() => {
  const { status } = props.redeMaisSaudeAppointment;

  return status.replace(
    new RegExp(REDUNDANT_STATUS_TEXTS.join('|'), 'gi'),
    '',
  ).trim();
});

</script>
<template>
  <div class="flex w-full flex-col rounded-lg border border-gray-200 bg-white p-6 shadow md:p-8">
    <div class="flex w-full flex-col-reverse items-center justify-between gap-2 md:flex-row md:gap-0">
      <div
        class="grow text-xl font-bold text-gray-800"
      >
        {{
          toNormalCase(redeMaisSaudeAppointment.specialty) ||
            $t('serviceRedemptions.redeMaisSaudeAppointment.defaultSpecialtyName')
        }}
      </div>
      <div class="w-fit rounded-xl bg-primary/100 px-2 py-1 text-center text-xs text-white">
        {{ toNormalCase(statusWithoutRedundantText) }}
      </div>
    </div>
    <hr class="mb-6 mt-4 w-full border-gray-200">
    <div class="flex flex-row items-end justify-between gap-2 md:gap-0">
      <div class="flex flex-col items-start gap-2 md:gap-4">
        <span class="text-xs text-gray-500">
          {{ $t('serviceRedemptions.redeMaisSaudeAppointment.beneficiaryName') }}
        </span>
        <span class="text-sm text-gray-900 md:text-base md:font-medium">
          {{ toNormalCase(redeMaisSaudeAppointment.beneficiaryName) }}
        </span>
      </div>

      <div
        v-if="redeMaisSaudeAppointment.nationalId"
        class="flex flex-col items-start gap-3"
      >
        <span class="text-xs text-gray-500">
          {{ $t('serviceRedemptions.redeMaisSaudeAppointment.nationalId') }}
        </span>
        <span class="text-sm text-gray-900 md:text-base md:font-medium">
          {{ redeMaisSaudeAppointment.nationalId }}
        </span>
      </div>
    </div>
    <div
      v-if="isValidDate(new Date(redeMaisSaudeAppointment.confirmedDateTime))"
      class="flex flex-col items-start text-sm md:text-base"
    >
      <hr class="mb-2 mt-6 w-full border-gray-200">
      <span class="mb-4 text-sm text-gray-500">
        {{ $t('serviceRedemptions.redeMaisSaudeAppointment.confirmedDateTime') }}
      </span>
      <div class="flex w-full flex-col justify-between gap-3 text-sm text-gray-500 md:flex-row md:gap-0">
        <div
          v-if="confirmedDate"
          class="col-span-2 flex flex-row items-center gap-2"
        >
          <base-svg
            name="calendar"
            class="size-4 stroke-current"
          />
          <span>
            {{ confirmedDate }}
          </span>
        </div>
        <div
          v-if="confirmedTime"
          class="flex flex-row items-center gap-2"
        >
          <base-svg
            name="clock"
            class="size-4 stroke-current"
          />
          <span>
            {{ confirmedTime }} h
          </span>
        </div>
        <div
          v-if="redeMaisSaudeAppointment.providerName"
          class="flex flex-row items-center gap-2"
        >
          <base-svg
            name="cardiology"
            class="size-4 stroke-current"
          />
          <span>
            {{ redeMaisSaudeAppointment.providerName }}
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="redeMaisSaudeAppointment.baseCost && redeMaisSaudeAppointment.discountedCost"
    >
      <hr class="col-span-4 my-6 w-full border-gray-200">
      <div class="flex flex-col justify-between gap-2 md:flex-row md:items-end">
        <div>
          <span class="text-xs text-gray-500">
            {{ $t('serviceRedemptions.redeMaisSaudeAppointment.baseCost') }}
          </span>
          <div class="flex h-full flex-row items-center justify-between gap-4">
            <span class="text-xl text-gray-800 line-through md:text-2xl">
              $ {{ redeMaisSaudeAppointment.baseCost }}
            </span>
            <span class="rounded-xl border border-primary/100 px-2 text-sm text-primary/100">
              {{ $t('serviceRedemptions.redeMaisSaudeAppointment.discountRate', { rate: discountRate }) }}
            </span>
          </div>
        </div>
        <div class="flex flex-row items-baseline gap-2 self-end">
          <span class="mr-2 text-sm text-gray-500 md:mr-4">
            {{ $t('serviceRedemptions.redeMaisSaudeAppointment.discountedCost') }}:
          </span>
          <span class="text-2xl font-medium text-gray-800 md:text-4xl">
            $ {{ redeMaisSaudeAppointment.discountedCost }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
