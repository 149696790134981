import type { AxiosPromise } from 'axios';

import api from './index';

const BASE_PATH = '/api/internal/points';

export interface ResponsePoints {
  data: {
    points: number;
  };
}

export default {
  points() {
    const path = `${BASE_PATH}`;

    return api({
      method: 'get',
      url: path,
    }) as AxiosPromise<ResponsePoints>;
  },
};
