import type { CouponRedemption } from '@/types/coupon-redemption';

import api from './index';

const BASE_PATH = '/api/internal/coupon_redemptions';

export default {
  async create(couponId: number) : Promise<CouponRedemption> {
    const response = await api({
      method: 'post',
      url: BASE_PATH,
      data: { couponId },
    });

    return response.data.couponRedemption;
  },
};
