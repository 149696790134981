<script setup lang="ts">
import { computed, useAttrs } from 'vue';

interface Props {
  imageUrl?: string;
  resizedWebpImageUrl?: string;
  imageBlurhash?: string;
  overlayLogoUrl?: string;
  title: string;
  description: string;
  iconSrc?: Record<string, string | undefined>;
  iconColor?: 'primary' | 'secondary';
  titleSize: 'small' | 'large';
  isModalCard?: boolean
}

const props = withDefaults(
  defineProps<Props>(),
  {
    imageUrl: undefined,
    resizedWebpImageUrl: undefined,
    imageBlurhash: undefined,
    overlayLogoUrl: undefined,
    iconSrc: undefined,
    iconColor: 'secondary',
    isModalCard: false,
  },
);

const attrs = useAttrs();
const componentTag = computed(() => (attrs.href ? 'a' : 'div'));
const smallTitleSize = computed(() => props.titleSize === 'small');
const largeTitleSize = computed(() => props.titleSize === 'large');

</script>
<template>
  <component
    :is="componentTag"
    class="flex w-min flex-col bg-white"
    :class="{ 'rounded-lg border border-gray-200 shadow': !isModalCard }"
  >
    <div class="relative">
      <base-image
        v-if="imageUrl"
        img-styles="aspect-3/2 w-80 rounded-t-lg"
        :src="imageUrl"
        :webp-src="resizedWebpImageUrl"
        :blurhash="imageBlurhash"
        alt=""
      />
      <div
        v-else
        class="flex aspect-3/2 w-full items-center justify-center rounded-t-lg bg-slate-200 text-slate-500"
      >
        <base-svg
          name="image"
          class="m-auto size-20 fill-current"
        />
      </div>
      <base-image
        v-if="overlayLogoUrl"
        :src="overlayLogoUrl"
        class="bottom-0 left-0 h-12 fill-current"
        absolute
      />
      <slot name="image-decoration" />
    </div>
    <slot name="header" />
    <div class="flex w-64 grow flex-col justify-between p-7 sm:w-80">
      <div class="mb-2 space-y-2">
        <base-svg
          v-if="iconSrc"
          data-testid="card-icon-svg"
          v-bind="iconSrc"
          class="size-10 fill-current"
          :class="`text-${iconColor}`"
        />
        <h4
          class="font-bold"
          :class="{'text-base': smallTitleSize, 'text-3xl': largeTitleSize}"
        >
          {{ title }}
        </h4>
        <base-rich-text
          class="h-24 truncate font-normal text-gray-700"
          :text="description"
        />
      </div>
      <slot name="call-to-action" />
    </div>
  </component>
</template>
