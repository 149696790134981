import type { CuponstarCategory } from '@/types/cuponstar-category';

import api from './index';

const BASE_PATH = '/api/internal/cuponstar_categories';

export default {
  async redeemable(): Promise<CuponstarCategory[]> {
    const response = await api({
      method: 'get',
      url: `${BASE_PATH}/redeemable`,
    });

    return response.data.cuponstarCategories as CuponstarCategory[];
  },
  async included(): Promise<CuponstarCategory[]> {
    const response = await api({
      method: 'get',
      url: `${BASE_PATH}/included`,
    });

    return response.data.cuponstarCategories as CuponstarCategory[];
  },
};
