import type { User } from '@/types/user';
import type { UserEditFormData } from '@/types/user-edit-form-data';

import api from './index';

const BASE_PATH = '/api/internal/user';

export default {
  async update(userData: UserEditFormData) : Promise<User> {
    const response = await api({
      method: 'patch',
      url: BASE_PATH,
      data: { user: userData },
    });

    return response.data.user;
  },
};
