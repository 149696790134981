<script setup lang="ts">
import { computed } from 'vue';
import { useMq } from 'vue3-mq';

import useFamilyServicesFilters from '@/composables/useFamilyServicesFilters';
import type { SixthMainContent } from '@/types/sixth-main-content';
import familyIconSrc from '@/utils/family-icon-src';
import gridItemClasses from '@/utils/sixth-main-content-grid-styles';

import PaginatedServices from './paginated-services.vue';
import SixthMainContentServiceCard from './sixth-main-content-service-card.vue';

interface Props {
  sixthMainContent: SixthMainContent;
}

const props = defineProps<Props>();

const iconColor = props.sixthMainContent.iconColor ?? 'primary';

const families = computed(() => props.sixthMainContent.families);

const mq = useMq();

const {
  couponFamily,
  familiesWithAllServices,
  hasFamiliesOrCoupons,
  selectedFamilyIndex,
  selectFamily,
  services,
} = useFamilyServicesFilters(families);

const showAllServices = computed(() => !selectedFamilyIndex.value);

const selectedFamilyClass = computed(() => {
  const colorClassByIconColor = {
    primary: 'border-primary text-primary',
    secondary: 'border-secondary text-secondary',
  };

  return `border-b-2 ${colorClassByIconColor[iconColor]}`;
});

function isFamilySelected(index: number) {
  return selectedFamilyIndex.value === index;
}

function iconColorClass() {
  const colorClassByIconColor = {
    primary: 'text-primary',
    secondary: 'text-secondary',
  };

  return colorClassByIconColor[iconColor];
}
</script>
<template>
  <main
    v-if="hasFamiliesOrCoupons"
    id="main-content"
    class="mx-auto mb-7 mt-12 flex w-full flex-col gap-y-8 px-6 sm:mt-24 sm:gap-y-7 sm:px-16 md:max-w-screen-2xl"
  >
    <h2
      class="text-center text-xl font-medium sm:text-5xl"
    >
      {{ sixthMainContent.title ?? $t('sections.mainContents.shared.title') }}
    </h2>
    <div
      class="flex justify-center sm:mb-4"
    >
      <ul class="flex gap-x-6 overflow-x-auto sm:gap-x-16 [&::-webkit-scrollbar]:hidden">
        <li
          v-for="(family, index) in familiesWithAllServices"
          :key="family.id"
          class="whitespace-nowrap"
          :class="{ [selectedFamilyClass]: isFamilySelected(index) }"
        >
          <a
            href="#main-content"
            class="inline-block cursor-pointer px-5 py-3"
            @click.stop="selectFamily(index)"
          >
            {{ family.name }}
          </a>
        </li>
        <li
          v-if="couponFamily"
          class="whitespace-nowrap"
        >
          <a
            :href="couponFamily.showPath"
            class="inline-block cursor-pointer px-5 py-3"
          >
            {{ $t('coupons.titleShort') }}
          </a>
        </li>
      </ul>
    </div>
    <section
      v-if="showAllServices"
    >
      <template
        v-if="!mq.lgPlus"
      >
        <paginated-services
          v-slot="{ currentPageServices }"
          :services="services"
        >
          <sixth-main-content-service-card
            v-for="service in currentPageServices"
            :key="service.id"
            :service="service"
          />
        </paginated-services>
      </template>

      <template
        v-else
      >
        <article
          v-for="family in families"
          :key="`family-${family.id}`"
          class="mb-20"
        >
          <header class="mb-10 flex items-center gap-x-2">
            <base-svg
              data-testid="family-icon-svg"
              v-bind="familyIconSrc(family)"
              class="size-8 fill-current"
              :class="iconColorClass()"
            />
            <h3
              data-testid="family-name"
              class="text-3xl"
            >
              {{ family.name }}
            </h3>
          </header>
          <section class="grid grid-cols-9 gap-5">
            <sixth-main-content-service-card
              v-for="(service, itemIndex) in family.allIncludedServices"
              :key="`service-${service.id}`"
              :class="gridItemClasses({
                itemIndex,
                numberOfItems: family.allIncludedServices?.length ?? 0
              })"
              :service="service"
            />
          </section>
        </article>
      </template>
    </section>
    <div
      v-else
      class="overflow-x-auto"
    >
      <!-- eslint-disable tailwindcss/no-custom-classname -->
      <section
        class="grid min-w-96 grid-cols-9 gap-5"
      >
        <!-- eslint-enable tailwindcss/no-custom-classname -->
        <sixth-main-content-service-card
          v-for="(service, itemIndex) in services"
          :key="`service-${service.id}`"
          :class="gridItemClasses({
            itemIndex,
            numberOfItems: services.length
          })"
          :service="service"
        />
      </section>
    </div>
  </main>
</template>
