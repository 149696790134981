import brNationalIdUtils from './br/national-id-utils';
import clNationalIdUtils from './cl/national-id-utils';
import esNationalIdUtils from './es/national-id-utils';
import peNationalIdUtils from './pe/national-id-utils';

type CountryCodes = 'BR'| 'CL' | 'ES' | 'PE';

type NationalIdUtilsType = {
  format: (dni: string) => string;
  validate: (dni: string) => boolean;
}

type NationalIdUtilsMap = {
  [key in CountryCodes]: NationalIdUtilsType | undefined;
}

const nationalIdUtilsMap: NationalIdUtilsMap = {
  BR: brNationalIdUtils,
  CL: clNationalIdUtils,
  ES: esNationalIdUtils,
  PE: peNationalIdUtils,
};

function nationalIdUtils(countryCode: string) {
  return nationalIdUtilsMap[countryCode as CountryCodes] || { format: undefined, validate: undefined };
}

export default nationalIdUtils;
