<script setup lang="ts">
import { useFloating,
  offset,
  flip,
  shift,
  arrow,
} from '@floating-ui/vue';
import { ref, computed } from 'vue';
import { useMq } from 'vue3-mq';

defineProps<{
  text: string,
}>();

const isVisible = ref(false);

function showTooltip() {
  isVisible.value = true;
}

function hideTooltip() {
  isVisible.value = false;
}

const reference = ref(null);
const floating = ref(null);
const floatingArrow = ref(null);
const OFFSET_PX = 6;
const XS_ARROW_ADJUSTMENT = 1.6;
const middleware = ref([
  arrow({ element: floatingArrow }),
  offset(OFFSET_PX),
  flip(),
  shift(),
]);
const { floatingStyles, middlewareData } = useFloating(reference, floating, { placement: 'top', middleware });

const mq = useMq();
const arrowPosition = computed(() => {
  if (middlewareData.value?.arrow?.x !== undefined) {
    const leftPositioning = mq.xs ? middlewareData.value.arrow.x * XS_ARROW_ADJUSTMENT : middlewareData.value.arrow.x;

    return { left: `${leftPositioning}px` };
  }

  return {};
});
</script>

<template>
  <button
    class="relative"
    type="button"
    @mouseover="showTooltip"
    @mouseout="hideTooltip"
    @click="showTooltip"
  >
    <div ref="reference">
      <slot />
    </div>
    <div
      v-if="isVisible"
      ref="floating"
      class="rounded-lg bg-primary px-4 py-2 text-xs text-white shadow-lg"
      :style="floatingStyles"
    >
      <p class="whitespace-nowrap">
        {{ text }}
      </p>
      <div
        ref="floatingArrow"
        class="absolute top-[90%] border-8 border-x-transparent border-b-transparent border-t-primary content-['']"
        :style="arrowPosition"
      />
    </div>
  </button>
</template>
