<script setup lang="ts">
import { computed } from 'vue';

import type { Category } from '@/types/category';
import type { Service } from '@/types/service';
import type { Sponsor } from '@/types/sponsor';
import type { WebApp } from '@/types/web-app';
import familyIconSrc from '@/utils/family-icon-src';

import ServiceCard from './service-card.vue';

const props = defineProps<{
  sponsor: Sponsor;
  webApp: WebApp;
  family: Category;
  categories: Category[];
  backUrl: string;
  servicesWithoutCategory: Service[];
}>();

const iconSrc = computed(() => familyIconSrc(props.family));
const showOtherSection = props.servicesWithoutCategory && props.servicesWithoutCategory.length > 0;
</script>

<template>
  <div class="bg-gray-50 py-5 md:py-9">
    <div class="space-y-5 px-6 md:space-y-10 md:px-20">
      <base-link
        icon-file-name="back"
        icon-position="left"
        icon-size="sm"
        :label="$t('common.back')"
        theme="gray"
        :href="backUrl"
      />
      <div class="block md:flex md:items-center">
        <base-svg
          v-bind="iconSrc"
          class="mb-1 mr-7 size-11 fill-current text-secondary md:mb-0"
        />
        <h1 class="text-3xl font-bold md:text-4xl">
          {{ family.name }}
        </h1>
      </div>
    </div>
  </div>
  <div class="mt-11">
    <div
      v-for="category in categories"
      :key="category.id"
      class="mb-12 md:mb-24"
    >
      <h2 class="px-6 text-2xl font-bold md:px-20">
        {{ category.name }}
      </h2>
      <div class="mt-7 grid grid-cols-1 gap-6 px-6 md:mt-10 md:grid-cols-3 md:px-20">
        <service-card
          v-for="service in category.services"
          :key="service.id"
          :service="service"
        />
      </div>
    </div>
  </div>
  <h2
    v-if="showOtherSection"
    class="px-6 text-2xl font-bold md:px-20"
  >
    {{ $t('categories.titles.more') }}
  </h2>
  <div class="mb-20 mt-7 grid grid-cols-1 gap-6 px-6 md:mt-10 md:grid-cols-3 md:px-20 ">
    <service-card
      v-for="service in servicesWithoutCategory"
      :key="service.id"
      :service="service"
    />
  </div>
</template>
