<script setup lang="ts">
import type { SixthHero } from '@/types/sixth-hero';
import scrollToElement from '@/utils/scroll-to-element';

interface Props {
  sixthHero: SixthHero;
}

defineProps<Props>();

const ringClasses = `before:aspect-square before:absolute before:left-2/3 before:w-[17.5%]
                     before:rounded-full before:border-4 before:border-primary before:md:left-[58%]`;
const circleClasses = `after:aspect-square after:absolute after:bottom-[2%] after:left-1/3 after:w-[3%]
                       after:bg-primary after:rounded-full after:md:bottom-[10%] after:md:w-[5%]`;

</script>

<template>
  <div class="my-8 flex w-full px-6 sm:px-16">
    <div class="mx-auto flex w-full flex-col gap-y-10 md:max-w-screen-2xl md:flex-row  lg:gap-x-16 xl:gap-x-28">
      <div class="m-auto h-fit w-full grow md:aspect-square md:basis-0">
        <div
          class="relative aspect-3/2 md:aspect-square"
          :class="`${ringClasses} ${circleClasses}`"
        >
          <base-image
            v-if="sixthHero.squareImageUrl"
            data-testid="square-image"
            class="left-0 top-2 aspect-square w-7/12 max-w-full overflow-hidden rounded-2xl md:top-[8%] md:h-[68.75%] md:w-auto"
            :src="sixthHero.squareImageUrl"
            :webp-src="sixthHero.webpSquareImageUrl"
            :blurhash="sixthHero.squareImageBlurhash"
            :alt="$t('sections.heroes.sixthHero.imageAltText')"
            absolute
          />
          <base-image
            v-if="sixthHero.circularImageUrl"
            data-testid="circular-image"
            class="bottom-0 right-0 aspect-square h-2/3 overflow-hidden rounded-full md:h-[43.75%] md:w-auto"
            :src="sixthHero.circularImageUrl"
            :webp-src="sixthHero.webpCircularImageUrl"
            :blurhash="sixthHero.circularImageBlurhash"
            :alt="$t('sections.heroes.sixthHero.imageAltText')"
            absolute
          />
        </div>
      </div>
      <div class="flex w-full grow flex-col justify-center gap-y-10 md:order-1 md:ml-10 md:basis-0">
        <base-rich-text
          data-testid="title"
          class="text-2xl font-semibold md:text-3xl"
          :text="sixthHero.richTitle ?? $t('sections.heroes.shared.title')"
        />
        <base-rich-text
          data-testid="description"
          :text="sixthHero.richDescription ?? $t('sections.heroes.shared.description')"
        />
        <base-button
          theme="primary-button"
          data-testid="call-to-action-button"
          class="self-center md:self-start"
          @click="scrollToElement('#main-content')"
        >
          {{ sixthHero.callToAction ?? $t('sections.heroes.shared.callToAction') }}
        </base-button>
      </div>
    </div>
  </div>
</template>
