<script setup lang="ts">
import { csrfToken } from '@rails/ujs';
import { inject, computed } from 'vue';
import { useMq } from 'vue3-mq';

import type { SecondNavbar } from '@/types/second-navbar';
import type { User } from '@/types/user';

import FriendlyNavbarMenu from './friendly-navbar-menu.vue';
import NavbarDropdown from './navbar-dropdown.vue';

interface Props {
  secondNavbar: SecondNavbar;
}
const mq = useMq();

const props = defineProps<Props>();

const currentUser = inject<User | undefined>('currentUser');

const logos = computed(() => ({
  transparent: props.secondNavbar.darkBgLogoUrl,
  solid: props.secondNavbar.logoUrl,
}));

const mobileLogos = computed(() => ({
  transparent: props.secondNavbar.darkMobileLogoUrl,
  solid: props.secondNavbar.mobileLogoUrl,
}));

const loginLinkThemes = {
  transparent: 'white',
  solid: 'primary',
};

const textColorClasses = {
  transparent: 'text-white',
  solid: 'text-black',
};

const styles = {
  transparent: 'bg-transparent absolute top-0',
  solid: 'bg-white',
};

const logo = computed(() => (logos.value[props.secondNavbar.theme]));
const mobileLogo = computed(() => (mobileLogos.value[props.secondNavbar.theme]));
const loginLinkTheme = computed(() => (loginLinkThemes[props.secondNavbar.theme]));
const textColorClass = computed(() => (textColorClasses[props.secondNavbar.theme]));
const style = computed(() => (styles[props.secondNavbar.theme]));

</script>

<template>
  <div
    class="z-30 flex w-full justify-center sm:py-2"
    :class="style"
  >
    <div class="flex w-full items-center justify-between gap-x-4 p-6 sm:px-16 md:max-w-screen-2xl">
      <div
        v-if="mq.xs"
        class="flex items-center"
      >
        <a href="/">
          <img
            v-if="mobileLogo"
            class="h-11 sm:h-16"
            alt="logo"
            :src="mobileLogo"
          >
        </a>
      </div>
      <div
        v-else
        class="flex items-center"
      >
        <a href="/">
          <img
            v-if="logo"
            class="h-11 sm:h-16"
            alt="logo"
            :src="logo"
          >
        </a>
      </div>
      <template v-if="secondNavbar.authUrl">
        <friendly-navbar-menu
          v-if="currentUser"
          :current-user="currentUser"
          :logo-url="secondNavbar.logoUrl"
          :edit-user-path="secondNavbar.editUserPath"
          :service-redemptions-path="secondNavbar.serviceRedemptionsPath"
          :class="textColorClass"
        />
        <template v-else>
          <navbar-dropdown
            class="sm:hidden"
            :class="textColorClass"
            :auth-url="secondNavbar.authUrl"
          />
          <form
            :action="secondNavbar.authUrl"
            method="post"
            class="ml-auto hidden items-center sm:flex"
          >
            <base-link
              data-testid="register-button"
              :theme="loginLinkTheme"
              type="submit"
              class="text-sm font-semibold"
              :label="$t('sections.navbars.shared.register')"
            />
            <input
              type="hidden"
              name="authenticity_token"
              :value="csrfToken()"
              tabindex="-1"
            >
            <input
              type="hidden"
              name="prompt"
              value="create"
              tabindex="-1"
            >
          </form>
          <form
            :action="secondNavbar.authUrl"
            method="post"
            class="ml-6 hidden items-center sm:flex"
          >
            <base-button
              data-testid="login-button"
              theme="primary-button"
              type="submit"
            >
              <span class="text-sm font-semibold">
                {{ $t('sections.navbars.shared.login') }}
              </span>
            </base-button>
            <input
              type="hidden"
              name="authenticity_token"
              :value="csrfToken()"
              tabindex="-1"
            >
          </form>
        </template>
      </template>
    </div>
  </div>
</template>
