import { ref, watch, type Ref } from 'vue';

const DEFAULT_VISIBILITY_TIMEOUT = 3000;

interface Props {
  condition: Ref<boolean>;
  reload?: boolean;
  timeout?: number;
}

export default function useVisibilityTimeout(props: Props) {
  const isVisible = ref(props.condition.value);

  const timeout = props.timeout || DEFAULT_VISIBILITY_TIMEOUT;

  function setIsVisibleFalseAfterTimeout() {
    setTimeout(() => {
      isVisible.value = false;
      if (props.reload) window.location.reload();
    }, timeout);
  }

  if (isVisible.value) setIsVisibleFalseAfterTimeout();

  watch(props.condition, (newValue) => {
    isVisible.value = props.condition.value;
    if (newValue) setIsVisibleFalseAfterTimeout();
  });

  return { isVisible };
}
