<script setup lang="ts">
import { computed } from 'vue';

type Variant = 'primary' | 'secondary';
type Size = 'large' | 'medium';
type Color = 'amber' | 'primary' | 'gray';

const props = withDefaults(
  defineProps<{
    size?: Size;
    variant?: Variant;
    color?: Color;
    label: string;
    iconPosition?: 'left' | 'right';
    icon?: string;
  }>(),
  {
    size: 'medium',
    variant: 'primary',
    color: 'primary',
    iconPosition: 'left',
    icon: undefined,
  },
);

type SizeStyles = {
  [key in Size]: Record<string, string | string[]>;
}

type VariantStyles = {
  [key in Variant]: Record<string, string>;
}

type ColorClasses = {
  [key in Color]: string;
}

// eslint-disable-next-line complexity
const sizeStyles = computed<SizeStyles>(() => ({
  large: {
    container: [
      'h-8 py-2',
      props.icon && props.iconPosition === 'left' ? 'pl-2' : 'pl-3',
      props.icon && props.iconPosition === 'right' ? 'flex-row-reverse pr-2' : 'pr-3',
    ],
    icon: ['size-4 max-h-4 max-w-4'],
    label: 'text-sm',
  },
  medium: {
    container: [
      'h-6 py-1.5',
      props.icon && props.iconPosition === 'left' ? 'pl-2' : 'pl-3',
      props.icon && props.iconPosition === 'right' ? 'flex-row-reverse pr-2' : 'pr-3',
    ],
    icon: 'size-3 max-h-3 max-w-3',
    label: 'text-xs',
  },
}));

const currentSizeStyles = computed(() => sizeStyles.value[props.size]);
/* Force tailwind to include the possible classes in bundle */
// @ts-expect-error unused variable
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const colorClasses: ColorClasses = {
  amber: 'bg-amber-100 bg-amber-700 border-amber-200 text-amber-700',
  primary: 'bg-primary-100 bg-primary-700 border-primary-200 text-primary-700',
  gray: 'bg-gray-100 bg-gray-700 border-gray-200 text-gray-700',
};

const variantStyles = computed<VariantStyles>(() => ({
  primary: {
    container: `bg-${props.color}-100 text-${props.color}-700 border-transparent`,
  },
  secondary: {
    container: `bg-white text-${props.color}-700 border-${props.color}-200`,
  },
}));

const currentVariantStyles = computed(() => variantStyles.value[props.variant]);
</script>

<template>
  <div
    class="inline-flex items-center justify-center gap-1 rounded-3xl border text-2xs"
    :class="[currentSizeStyles.container, currentVariantStyles.container]"
  >
    <base-svg
      v-if="props.icon"
      :name="props.icon"
      class="fill-current"
      :class="currentSizeStyles.icon"
    />
    <span
      class="truncate"
      :class="currentSizeStyles.label"
    >
      {{ props.label }}
    </span>
  </div>
</template>
