<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

import eventsApi from '@/api/events';
import { MediaItem } from '@/types/media-item';
import type { Service } from '@/types/service';

import BaseMediaPlayer from './base-media-player.vue';

const props = defineProps<{
  mediaItem: MediaItem;
  backUrl: string;
  service: Service;
}>();

const player = ref<InstanceType<typeof BaseMediaPlayer>>();
const finished = ref(false);
const unloaded = ref(false);
const played = computed(() => player.value?.mediaPlayed);
const duration = computed(() => player.value?.mediaDuration);
const rangeLength = computed(() => played.value?.length || 0);

const playedInSeconds = computed(() => {
  if (!duration.value) return 0;
  let totalSeconds = 0;

  try {
    for (let i = 0; i < rangeLength.value; i++) {
      totalSeconds += (played.value?.end(i) || 0) - (played.value?.start(i) || 0);
    }

    return totalSeconds;
  } catch {
    return 0;
  }
});

const playedPercentage = computed(() => {
  if (!duration.value) return 0;
  const MAX_PERCENTAGE = 100;

  return (playedInSeconds.value / duration.value) * MAX_PERCENTAGE;
});

function onStarted() {
  eventsApi.send('video_start', props.service.id, {
    mediaItemId: props.mediaItem.id,
  });
}

function onEnded() {
  finished.value = true;
  eventsApi.send('video_finish', props.service.id, {
    mediaItemId: props.mediaItem.id,
  });
}

function onBeforeUnload() {
  if (!finished.value && !unloaded.value) {
    unloaded.value = true;
    eventsApi.sendKeepAlive('video_gave_up', props.service.id, {
      mediaItemId: props.mediaItem.id,
      mediaItemDuration: duration.value,
      mediaItemSecondsWatched: playedInSeconds.value,
      mediaItemPercentageWatched: playedPercentage.value,
    });
  }
}

onMounted(() => {
  window.addEventListener('beforeunload', onBeforeUnload);
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', onBeforeUnload);
});
</script>

<template>
  <main class="mx-auto w-full px-6 sm:px-16 md:max-w-screen-2xl md:pt-20">
    <base-link
      theme="gray"
      icon-file-name="arrow-left"
      icon-position="left"
      icon-size="md"
      :label="$t('common.back')"
      class="mb-9 gap-2.5 font-medium"
      :href="backUrl"
    />
    <header>
      <div class="mb-6 grid grid-cols-12 border-b border-black/15 pb-7">
        <div class="col-span-10">
          <h1 class="mb-8 text-2xl font-medium">
            {{ props.mediaItem.name }}
          </h1>
          <p class="flex flex-row items-center gap-4">
            <span
              v-if="props.mediaItem.authorName"
              class="text-gray-700"
            >
              {{ $t('serviceMediaItem.author') }}: {{ props.mediaItem.authorName }}
            </span>
            <span
              v-if="props.mediaItem.authorName"
              class="h-3 w-0 border-r border-black/15"
            />
            <span>
              {{ props.mediaItem.languageFlag || props.mediaItem.language }}
            </span>
          </p>
        </div>
      </div>
      <div class="mb-8 grid grid-cols-1 gap-6 md:grid-cols-12">
        <div
          class="pl-14 pr-6 md:col-span-6 "
          :class="{ 'border-r border-black/15': props.mediaItem.requirements }"
        >
          <h3 class="-ml-14 mb-1 flex gap-6 text-gray-700">
            <span class="inline-flex size-8 items-center justify-center rounded-full bg-gray-100">
              <base-svg
                name="thin-timer"
                class="stroke-black stroke-1.5"
              />
            </span> {{ $t('serviceMediaItem.description') }}:
          </h3>
          <p class="text-sm leading-5">
            {{ props.mediaItem.description }}
          </p>
        </div>
        <div
          v-if="props.mediaItem.requirements"
          class="pl-14 pr-6 md:col-span-3"
        >
          <h3 class="-ml-14 flex gap-6 text-gray-700">
            <span class="inline-flex size-8 items-center justify-center rounded-full bg-gray-100">
              <base-svg
                name="thin-clipboardtext"
                class="stroke-black stroke-1.5"
              />
            </span> {{ $t('serviceMediaItem.requirements') }}
          </h3>
          <p class="text-sm leading-5">
            {{ props.mediaItem.requirements }}
          </p>
        </div>
      </div>
    </header>
    <div class="mb-8 md:mb-32">
      <base-media-player
        ref="player"
        :src="props.mediaItem.url"
        :title="props.mediaItem.name"
        :poster-url="props.mediaItem.imageUrl"
        @started="onStarted"
        @ended="onEnded"
      />
    </div>
  </main>
</template>
