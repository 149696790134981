import { computed, inject } from 'vue';

import type { WebApp } from '@/types/web-app';

export default function useShowRedeemableCoupons() {
  const webApp = inject<WebApp>('webApp');
  const hasCoupons = computed(() => webApp?.hasCoupons || false);
  const hasRedeemableCoupons = computed(() => webApp?.hasRedeemableCoupons || false);
  const showRedeemableCoupons = computed(() => hasCoupons.value && hasRedeemableCoupons.value);

  return { showRedeemableCoupons };
}
