<script setup lang="ts">
import { computed, useAttrs } from 'vue';

interface Props {
  iconFileName?: string;
  label?: string;
  theme: string;
  loading?: boolean;
  rightIcon?: boolean;
  smallIcon?: boolean;
  rounded?: 'md' | 'full';

}

const props = withDefaults(
  defineProps<Props>(),
  {
    iconFileName: undefined,
    label: undefined,
    loading: false,
    rightIcon: false,
    smallIcon: false,
    rounded: 'md',

  },
);

const roundedMd = computed(() => props.rounded === 'md');
const roundedFull = computed(() => props.rounded === 'full');

const themeStyles = {
  'black-button': 'text-white bg-black px-6',
  'primary-button': 'text-white bg-primary px-6 hover:bg-primary/70 disabled:text-gray-400 disabled:bg-gray-100 ',
  'primary-button-outline': 'text-primary border border-primary px-6 hover:bg-primary/10',
  'white': 'bg-white px-6',
};

const attrs = useAttrs();
const currentTheme = computed(() => themeStyles[props.theme as keyof typeof themeStyles]);
const currentTag = computed(() => (attrs.href ? 'a' : 'button'));
const iconSizeStyle = props.smallIcon ? 'h-2.5' : 'h-6';

</script>
<template>
  <component
    :is="currentTag"
    class="inline-flex h-min items-center justify-center py-2 text-center"
    :class="[currentTheme, { 'rounded-md': roundedMd, 'rounded-full': roundedFull }]"
  >
    <div
      v-if="loading"
      class="flex h-max grow items-center justify-center"
    >
      <base-svg
        name="loading"
        class="mr-2 h-6 animate-spin fill-current stroke-current"
      />
    </div>
    <base-svg
      v-else-if="!!iconFileName && !rightIcon"
      :name="iconFileName"
      class="mr-2 fill-current"
      :class="iconSizeStyle"
    />
    <span v-if="label">
      {{ label }}
    </span>
    <slot />
    <base-svg
      v-if="!!iconFileName && rightIcon"
      :name="iconFileName"
      class="ml-2 fill-current stroke-current"
      :class="iconSizeStyle"
    />
  </component>
</template>
