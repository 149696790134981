import type { Category } from '@/types/category';

function familyIconSrc(family: Category) {
  if (family.customIconUrl) {
    return {
      src: family.customIconUrl,
    };
  }

  return {
    name: family.icon,
  };
}

export default familyIconSrc;
