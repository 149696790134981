<script setup lang="ts">
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import StarterKit from '@tiptap/starter-kit';
import { useEditor, EditorContent } from '@tiptap/vue-3';

interface Props {
  text: string;
}
const props = defineProps<Props>();
const editor = useEditor({
  content: props.text,
  editable: false,
  extensions: [
    Color,
    TextStyle,
    StarterKit,

  ],
});
</script>
<template>
  <editor-content
    :editor="editor"
  />
</template>
<style lang="scss">

.ProseMirror:focus {
  @apply outline-gray-700;
}

.ProseMirror {
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  ul {
    padding: 0 1rem;
    @apply list-disc;
  }
  ol {
    padding: 0 1rem;
    @apply list-decimal;
  }
  blockquote {
    @apply pl-4 border-l-4 border-gray-300;
  }
}

</style>
