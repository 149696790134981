import { computed, type Ref } from 'vue';

const SWIPE_PIXELS = 280;
const SWIPE_DURATION = 800;

function scrollTo(element: HTMLElement, scrollPixels: number, duration: number) {
  const scrollPos = element.scrollLeft;
  const atStart = scrollPos === 0 || scrollPixels > 0;
  const goingLeft = scrollPixels < 0;
  const endReached = element.clientWidth + scrollPos === element.scrollWidth;
  const scrollable = !(atStart && (endReached || goingLeft));

  if (scrollable) {
    const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

    // eslint-disable-next-line no-inner-declarations
    function scroll(timestamp: number) {
      const timeElapsed = timestamp - startTime;
      const progress = Math.min(timeElapsed / duration, 1);
      element.scrollLeft = scrollPos + scrollPixels * progress;
      if (timeElapsed >= duration) return;
      window.requestAnimationFrame(scroll);
    }
    window.requestAnimationFrame(scroll);
  }
}

function swipeLeftConstructor(element: HTMLElement | undefined, swipePixels: number, swipeDuration: number) {
  return () => {
    if (element) scrollTo(element, -swipePixels, swipeDuration);
  };
}
function swipeRightConstructor(
  element: HTMLElement | undefined,
  swipePixels: number,
  swipeDuration: number,
) {
  return () => {
    if (element) scrollTo(element, swipePixels, swipeDuration);
  };
}

export default function useSwipe(
  elementRef: Ref<HTMLElement | undefined>,
  swipePixels = SWIPE_PIXELS,
  swipeDuration = SWIPE_DURATION,
) {
  const swipeLeft = computed(() => swipeLeftConstructor(elementRef.value, swipePixels, swipeDuration));
  const swipeRight = computed(() => swipeRightConstructor(elementRef.value, swipePixels, swipeDuration));

  return {
    swipeLeft,
    swipeRight,
  };
}
