<script setup lang="ts">
import { computed } from 'vue';

import useSiteVariables from '@/composables/useSiteVariables';
import type { Coupon } from '@/types/coupon';
import formatNumber from '@/utils/format-number';

interface Props {
  coupon: Coupon;
  variant?: 'default' | 'redeem-modal';
  categoryName?: string;
}
const props = withDefaults(defineProps<Props>(),
  {
    variant: 'default',
    categoryName: undefined,
  },
);

interface Emits {
  (e: 'call-to-action-clicked'): void;
}

const emit = defineEmits<Emits>();

const isDefault = computed(() => props.variant === 'default');
const isRedeemModal = computed(() => props.variant === 'redeem-modal');

const { locale: siteLocale } = useSiteVariables();
const parsedDate = new Date(props.coupon.expirationDate).toLocaleDateString(
  siteLocale,
  {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
);

const isRedeemed = computed(() => !!props.coupon.lastRedemption);

const redeemOptionIcons = {
  email: 'envelope',
  online: 'wifi-medium',
  onsite: 'map-pin',
  whatsapp: 'whatsapp',
  phone: 'phone',
};
</script>
<template>
  <base-card
    :image-url="coupon.imagePrimary"
    :title="coupon.name"
    :description="coupon.description"
    :overlay-logo-url="coupon.companyLogoThumbnail"
    :is-modal-card="isRedeemModal"
    title-size="small"
  >
    <template #header>
      <div
        v-if="isRedeemModal"
        class="-mb-6 px-7 pt-7"
      >
        <template
          v-for="(isAvailable, key) in props.coupon.redeemOptions"
          :key="key"
        >
          <base-mok-tag
            v-if="isAvailable"
            size="medium"
            color="amber"
            :icon="redeemOptionIcons[key as keyof typeof redeemOptionIcons]"
            :label="$t(`coupons.redeemOptions.${key}`)"
          />
        </template>
      </div>
    </template>
    <template #image-decoration>
      <div
        class="absolute -left-1.5 top-3 h-8 w-16 min-w-fit rounded-tr-2xl bg-amber-500 text-white"
      >
        <div class="mx-3 my-1 text-center font-bold">
          {{ coupon.discount }}
        </div>
      </div>
      <div
        class="absolute -left-1.5 top-11 h-2 w-1.5 bg-gradient-to-bl from-amber-600 from-50% to-white to-50%"
      />
    </template>

    <template #call-to-action>
      <base-button
        v-if="isDefault && isRedeemed"
        theme="primary-button"
        class="w-fit"
        @click="() => emit('call-to-action-clicked')"
      >
        <div class="flex justify-between gap-2">
          {{ $t('coupons.showCode') }}
          <base-svg
            name="chevron-right"
            class="size-6"
          />
        </div>
      </base-button>
      <base-button
        v-else-if="isDefault"
        theme="primary-button-outline"
        class="w-fit"
        @click="() => emit('call-to-action-clicked')"
      >
        <div class="flex justify-between gap-2">
          <span>
            {{
              coupon.pointCost === 0 ?
                $t('coupons.redeem') :
                $t('coupons.redeemWithPoints', { points: formatNumber(coupon.pointCost) })
            }}
          </span>
          <base-svg
            name="chevron-right"
            class="size-6"
          />
        </div>
      </base-button>
      <base-button
        v-else-if="isRedeemModal"
        theme="primary-button"
        class="w-full"
        @click="() => emit('call-to-action-clicked')"
      >
        <div class="flex justify-between gap-2">
          <span>

            {{
              coupon.pointCost === 0 ?
                $t('coupons.want') :
                $t('coupons.wantWithPoints', { points: formatNumber(coupon.pointCost) })
            }}
          </span>
        </div>
      </base-button>
      <div
        v-if="isRedeemModal"
        class="mt-6 flex flex-col gap-6"
      >
        <div>
          {{ $t('coupons.category', { category: categoryName }) }}
        </div>
        <div>
          {{ $t('coupons.expirationDate', { expirationDate: parsedDate }) }}
        </div>
      </div>
    </template>
  </base-card>
</template>
