<script setup lang="ts">
import { ref } from 'vue';

import useAttrsWithoutClass from '@/composables/useAttrsWithoutClass';

import BlurhashCanvas from './blurhash-canvas.vue';

interface Props {
  src: string;
  webpSrc?: string;
  blurhash?: string;
  imgStyles?: string;
  absolute?: boolean;
}

withDefaults(defineProps<Props>(),
  {
    webpSrc: undefined,
    blurhash: undefined,
    imgStyles: undefined,
    absolute: false,
  },
);
const isLoaded = ref(false);

const attrsWithoutClass = useAttrsWithoutClass();
</script>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<template>
  <div
    :class="[$attrs.class, {
      'absolute': absolute,
      'relative': !absolute,
    }]"
  >
    <blurhash-canvas
      v-if="blurhash"
      :blurhash="blurhash"
      class="absolute left-0 top-0 size-full object-cover transition-opacity duration-500"
      :class="isLoaded ? 'opacity-0' : 'opacity-100'"
    />
    <div
      v-else
      class="absolute left-0 top-0 size-full object-cover transition-opacity duration-500"
    />
    <picture>
      <source
        v-if="webpSrc"
        ref="webpSource"
        type="image/webp"
        :srcset="webpSrc"
      >
      <source
        ref="jpegSource"
        type="image/jpeg"
        :srcset="src"
      >
      <img
        ref="image"
        v-bind="attrsWithoutClass"
        class="object-cover transition-opacity duration-500"
        :class="[
          imgStyles || 'size-full',
          isLoaded ? 'opacity-100' : 'opacity-0'
        ]"
        :src="src"
        @load="isLoaded = true"
      >
    </picture>
  </div>
</template>
