<script setup lang="ts">
import { computed } from 'vue';
import { useMq } from 'vue3-mq';

import { Category } from '@/types/category';
import type { Service } from '@/types/service';

import FifthMainContentServiceCard from './fifth-main-content-service-card.vue';

interface Props {
  services: Service[];
  families: Category[];
  iconColor: 'primary' | 'secondary';
}

const props = defineProps<Props>();
const mq = useMq();

const groupedServices = computed(() => {
  const groupSize = 3;
  const groups: Service[][] = [];
  for (let i = 0; i < props.services.length; i += groupSize) {
    groups.push(props.services.slice(i, i + groupSize));
  }

  return groups;
});

</script>

<template>
  <div
    v-if="mq.xs"
    class="w-full bg-gray-100 pb-14 pt-4"
  >
    <base-swipeable-cards
      class="overflow-y-hidden px-6 sm:px-16 md:max-w-screen-2xl"
      :cards-count="groupedServices.length"
      :swipe-buttons-theme="iconColor"
    >
      <template #cards>
        <div
          v-for="(serviceGroup, index) in groupedServices"
          :key="`service-group-${index}`"
          data-test-id="service-group"
          class="flex flex-col gap-y-4"
        >
          <fifth-main-content-service-card
            v-for="service in serviceGroup"
            :key="`service-${service.id}`"
            :service="service"
            :icon-color="iconColor"
          />
        </div>
      </template>
    </base-swipeable-cards>
  </div>
  <template v-else>
    <div
      v-for="(family, familyIndex) in families"
      :key="`family-${family.id}`"
      class="w-full pb-14 pt-16"
      :class="{
        'bg-gray-100': familyIndex %2 == 0,
        'bg-white': familyIndex % 2 == 1
      }"
    >
      <h3 class="pb-14 text-center font-bold sm:text-3xl ">
        {{ family.name }}
      </h3>
      <base-swipeable-cards
        v-if="family.allIncludedServices"
        class="overflow-y-hidden px-6 sm:px-16 md:max-w-screen-2xl"
        :cards-count="family.allIncludedServices.length"
        :swipe-buttons-theme="iconColor"
      >
        <template #cards>
          <fifth-main-content-service-card
            v-for="service in family.allIncludedServices"
            :key="`service-${service.id}`"
            :service="service"
            :icon-color="iconColor"
          />
        </template>
      </base-swipeable-cards>
    </div>
  </template>
</template>
