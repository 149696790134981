<script setup lang="ts">
import { ref } from 'vue';

interface Props {
    iframeSrc: string | null;
    permissions?: string;
}

const MAX_WAIT_TIME = 10000;
const iframeElement = ref<HTMLIFrameElement | null>(null);
const errorModalShown = ref(false);
const loading = ref(true);

withDefaults(defineProps<Props>(), {
  permissions: '',
});

function redirectBack() {
  window.history.back();
}

setTimeout(() => {
  if (loading.value) {
    errorModalShown.value = true;
    loading.value = false;
  }
}, MAX_WAIT_TIME);

window.addEventListener('message', (event) => {
  if (event.data === 'iframeCallback') {
    location.href = '/';
  }
});

</script>

<template>
  <base-modal
    :open="errorModalShown"
    data-testid="error-modal"
    custom-width="md:w-96 m-2 md:mx-auto"
    custom-height="h-auto"
    position-option="mixed"
    sandbox="allow-top-navigation"
    @close="redirectBack"
  >
    <button
      class="absolute right-4 top-7"
      @click="redirectBack"
    >
      <base-svg
        name="close"
        class="size-4 fill-current"
      />
    </button>
    <div class="flex size-full flex-col items-center justify-center">
      <h1 class="text-2xl font-bold md:mb-8">
        {{ $t('iframe.serviceView.errorModal.title') }}
      </h1>
      <p>
        {{ $t('iframe.serviceView.errorModal.description') }}
      </p>
      <base-button
        theme="primary-button"
        class="mt-8 w-full md:mx-auto md:w-1/2"
        @click="redirectBack"
      >
        {{ $t('iframe.serviceView.errorModal.button') }}
      </base-button>
    </div>
  </base-modal>
  <div>
    <base-link
      theme="gray"
      icon-file-name="back"
      icon-position="left"
      icon-size="sm"
      :label="$t('common.back')"
      class="flex-none px-4 md:px-8"
      @click="redirectBack"
    />
    <div
      v-if="loading"
      data-testid="loading"
      class="flex h-max grow items-center justify-center"
    >
      <base-svg
        name="loading"
        class="mt-24 h-36 animate-spin stroke-current text-primary"
      />
    </div>
    <iframe
      v-if="iframeSrc"
      ref="iframeElement"
      data-testid="iframe"
      title="service-iframe"
      :src="iframeSrc"
      :allow="permissions"
      class="min-h-screen overflow-hidden"
      sandbox="allow-scripts allow-same-origin allow-pointer-lock"
      width="100%"
      height="100vh"
      allowfullscreen
      loading="lazy"
      @load="() => loading = false"
    />
  </div>
</template>
