export default function scrollToElement(id: string, fallback?: () => void) {
  const targetElement = document.querySelector(id);
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: 'smooth' });

    return;
  }
  if (fallback) {
    fallback();
  }
}
