import type { Category } from '@/types/category';

import api from './index';

const BASE_PATH = '/api/internal/redeemable_families/';

export default {
  async index(): Promise<Category[]> {
    const response = await api({
      method: 'get',
      url: BASE_PATH,
      headers: {
        'cache-control': 'no-cache',
      },
    });

    return response.data.redeemableFamilies as Category[];
  },
};
