<script setup lang="ts">
import { camelize } from 'humps';
import { computed } from 'vue';
import { useMutation, useQueryClient } from 'vue-query';

import eventsApi from '@/api/events';
import serviceRedemptionApi from '@/api/service-redemption';
import type { Service } from '@/types/service';
import formatNumber from '@/utils/format-number';
import { classesByRedemptionKind, iconSrcByRedemptionKind } from '@/utils/service-redemption-kind-maps';

interface Props {
  open: boolean;
  service: Service,
  points: number,
  units: string,
}
interface Emits {
  (e: 'close'): void;
  (e: 'open-success'): void;
  (e: 'open-error'): void;
}

const props = defineProps<Props>();
const queryClient = useQueryClient();
const loyaltyQueryKey = 'loyalty-get-points';
const redeemableFamiliesKey = 'redeemable-families';

const pointBalanceAfterRedemption = computed(() => (
  props.points - props.service.pointCost
));

const redemptionKind = computed(() => (
  camelize(props.service.redemptionKind)
));

const emit = defineEmits<Emits>();

const { mutate: redeemService, isLoading } = useMutation(
  () => serviceRedemptionApi.create(props.service.mokServicesId),
  {
    onSuccess: async () => {
      eventsApi.send('service_redeem', props.service.id);
      await queryClient.invalidateQueries(loyaltyQueryKey);
      await queryClient.invalidateQueries(redeemableFamiliesKey);
      emit('open-success');
      emit('close');
    },
    onError: () => {
      emit('open-error');
      emit('close');
    },
  },
);

</script>
<template>
  <base-modal
    :open="open"
    position-option="mixed"
    custom-width="w-full md:w-96"
    @close="$emit('close')"
  >
    <div class="flex w-full flex-col items-center pt-6 text-center">
      <button
        data-testid="close-button"
        class="absolute right-4 top-7 md:top-4"
        @click="$emit('close')"
      >
        <base-svg
          name="close"
          class="size-4 fill-current"
        />
      </button>
      <div class="flex size-28 items-center justify-center rounded-full bg-secondary/10">
        <base-svg
          name="trophy-cup"
          class="w-14 fill-secondary"
        />
      </div>
      <p class="mt-3 text-xl font-bold">
        {{ $t('loyalty.modals.redemptionConfirmation.title') }}
      </p>
      <div
        class="mt-2 flex items-center rounded-full px-2 py-px"
        :class="[classesByRedemptionKind(redemptionKind)]"
      >
        <base-svg
          :name="iconSrcByRedemptionKind(redemptionKind)"
          class="mr-0.5 h-4"
        />
        {{ $t(`loyalty.shared.redemptionKinds.${redemptionKind}`) }}
      </div>
      <p class="mt-2 text-xs">
        {{ $t(`loyalty.modals.redemptionConfirmation.subtitles.${redemptionKind}`) }}
      </p>
      <div class="mt-6 flex flex-wrap justify-center text-base">
        {{ $t('loyalty.modals.redemptionConfirmation.name') }}
        <div class="px-1 font-bold">
          {{ service.name }}
        </div>
      </div>
      <p class="mt-5 text-base">
        {{ $t('loyalty.modals.redemptionConfirmation.cost') }}
        <span class="ml-1 rounded bg-white px-2 py-1 text-sm text-primary">
          {{ formatNumber(service.pointCost) }} {{ units }}
        </span>
      </p>
      <p class="mt-5 text-base">
        {{ $t('loyalty.modals.redemptionConfirmation.balanceAfterRedemption') }}
        <span class="ml-1 rounded bg-white px-2 py-1 text-sm text-primary">
          {{ formatNumber(pointBalanceAfterRedemption) }} {{ units }}
        </span>
      </p>
      <base-button
        class="mt-12 h-10 w-full text-base font-normal"
        theme="primary-button"
        :loading="isLoading"
        :disabled="isLoading"
        @click="redeemService"
      >
        {{ isLoading ? '' : $t('loyalty.modals.redemptionConfirmation.button') }}
      </base-button>
    </div>
  </base-modal>
</template>
